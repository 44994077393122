import React from 'react';

import RegisterSecondTemplate from 'components/templates/RegisterSecond';

const RegisterSecond: React.FC = () => (
  <div className="p-registerSecond">
    <RegisterSecondTemplate />
  </div>
);

export default RegisterSecond;
