import React, { useMemo } from 'react';

import NumberCongrate from 'components/templates/NumberCongrate';
import { useAppSelector } from 'store/hooks';

const NumberCongratulation: React.FC = () => {
  const { data: profileData } = useAppSelector((state) => state.me);

  const numberSelectedList = useMemo(() => {
    if (!profileData) return [];
    return profileData.lucky_draws.map((val) => val.number_choosed.toString());
  }, [profileData]);

  // const socketLivestream = useCallback(() => {
  //   dispatch(getMeAction()).unwrap();
  // }, [dispatch]);

  // useEffect(() => {
  //   if (profileData) {
  //     const channel = echo.private(`EventTime.${profileData.event_time_code}`);
  //     channel.listen('updated', socketLivestream);
  //     return () => {
  //       channel.stopListening('updated', socketLivestream);
  //     };
  //   }
  //   return () => {};
  // }, [profileData, socketLivestream]);

  return (
    <div className="p-numberCongratulation">
      <NumberCongrate
        account={profileData?.account}
        time={profileData?.event_time.show_time_start}
        address={profileData?.event_time.address}
        numberPickedList={numberSelectedList}
        numberAvailable={profileData?.number_lucky}
      />
    </div>
  );
};

export default NumberCongratulation;
