/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import SLUG_PARAMS, { STATUC_SLUG } from './constants';

import { clearTokenSession, getTokenSession } from 'services/common/storage';
import { useAppSelector } from 'store';

export type KeyRoute = '/' | 'REGISTER' | 'CHUC_MUNG' | 'LOGIN' | 'WAITING'
| 'LUCKYDRAW' | 'THIEP_MOI' | 'FOYER' | 'LIVESTREAM'
| 'ENDING' | 'NUMBER_INSTRUCTION'
| 'PRIZE' | 'SPECIAL_PRIZE' | 'CHECKIN_START' | 'CHECKIN' | 'CHECKIN1' | 'CHECKIN2';

export type KeyMe = 'CHUC_MUNG' | 'WAITING' | 'LUCKYDRAW' | 'THIEP_MOI' | 'FOYER' | 'LIVESTREAM' | 'ENDING';

export const returnPath = (code: KeyRoute): string => {
  switch (code) {
    case 'CHUC_MUNG':
      return SLUG_PARAMS.NUMBER_CONGRATULATION;
    case 'LOGIN':
      return SLUG_PARAMS.NUMBER_LOGIN;
    case 'WAITING':
      return SLUG_PARAMS.WAITING;
    case 'LUCKYDRAW':
      return SLUG_PARAMS.NUMBER_WELCOME;
    case 'THIEP_MOI':
      return SLUG_PARAMS.NUMBER_INVITATION;
    case 'FOYER':
      return SLUG_PARAMS.FOYER;
    case 'NUMBER_INSTRUCTION':
      return SLUG_PARAMS.NUMBER_INSTRUCTION;
    case 'LIVESTREAM':
      return SLUG_PARAMS.LIVESTREAM;
    case 'REGISTER':
      return SLUG_PARAMS.REGISTER;
    case 'ENDING':
      return SLUG_PARAMS.ENDING;
    default:
      return '/';
  }
};

export const returnKeyRoute = (path: string): KeyRoute => {
  switch (path) {
    case SLUG_PARAMS.NUMBER_CONGRATULATION:
      return 'CHUC_MUNG';
    case SLUG_PARAMS.NUMBER_LOGIN:
      return 'LOGIN';
    case SLUG_PARAMS.WAITING:
      return 'WAITING';
    case SLUG_PARAMS.NUMBER_PICKING:
    case SLUG_PARAMS.NUMBER_INSTRUCTION:
    case SLUG_PARAMS.NUMBER_WELCOME:
      return 'LUCKYDRAW';
    case SLUG_PARAMS.NUMBER_INVITATION:
      return 'THIEP_MOI';
    case SLUG_PARAMS.FOYER:
      return 'FOYER';
    case SLUG_PARAMS.LIVESTREAM:
      return 'LIVESTREAM';
    case SLUG_PARAMS.PRIZE:
      return 'PRIZE';
    case SLUG_PARAMS.SPECIAL_PRIZE:
      return 'SPECIAL_PRIZE';
    case SLUG_PARAMS.CHECKIN_START:
      return 'CHECKIN_START';
    case SLUG_PARAMS.CHECKIN:
      return 'CHECKIN';
    case SLUG_PARAMS.ENDING:
      return 'ENDING';
    case SLUG_PARAMS.CHECKIN1:
      return 'CHECKIN1';
    case SLUG_PARAMS.CHECKIN2:
      return 'CHECKIN2';
    default:
      return '/';
  }
};

const RedirectToPage: React.FC = ({ children }) => {
  const { data: dataConfig, hasDone } = useAppSelector((state) => state.me);
  const { loading: loadLogin } = useAppSelector((state) => state.auth);
  const { data: generalData } = useAppSelector((state) => state.general);
  const location = useLocation();
  const history = useHistory();
  const token = getTokenSession();
  const match = useRouteMatch('/:code');
  useEffect(() => {
    if (token && location.pathname === SLUG_PARAMS.LOGOUT) {
      clearTokenSession();
      history.push(SLUG_PARAMS.NUMBER_LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location.pathname]);

  const ignoreRoute = ['CHECKIN_START', 'CHECKIN', 'PRIZE', 'SPECIAL_PRIZE'];
  useEffect(() => {
    if (match && !STATUC_SLUG.includes((match.params as any).code)) {
      return;
    }
    if (ignoreRoute.includes(returnKeyRoute(location.pathname)) || location.pathname.includes('giai-thuong')
    || location.pathname.includes('giai-dac-biet')
    ) return;
    if (generalData) {
      // REGISTER GENERAL ROUTING
      if (generalData.redirect_to_page === 'LOGIN' && (location.pathname === '/' || location.pathname === SLUG_PARAMS.REGISTER)) {
        history.push(returnPath(generalData.redirect_to_page as KeyRoute));
      } else if (generalData.redirect_to_page === 'REGISTER' && (location.pathname !== '/' && location.pathname !== SLUG_PARAMS.REGISTER)) {
        history.push(returnPath(generalData.redirect_to_page as KeyRoute));
      } else if (generalData.redirect_to_page === 'LOGIN' && location.pathname === '/intro') {
        history.push(returnPath(generalData.redirect_to_page as KeyRoute));
      } else if (generalData.redirect_to_page === 'REGISTER' && location.pathname === '/intro') {
        history.push('/');
      } else if (hasDone) {
        if (dataConfig) {
          if (!dataConfig.redirect_to_page.includes(returnKeyRoute(location.pathname))) {
            setTimeout(() => {
              history.push(returnPath(dataConfig.redirect_to_page[0] as KeyMe));
            }, location.pathname.includes(SLUG_PARAMS.FOYER) ? 5000 : 0);
          }
        } else {
          history.push(returnPath('LOGIN'));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConfig, generalData, location.pathname]);

  if (loadLogin) return <>loading</>;

  return null;
};

export default RedirectToPage;
