import React from 'react';

import mapModifiers from 'utils/functions';

interface HeadingSectionProps {
  modifiers?: 'secondary';
}

const HeadingSection: React.FC<HeadingSectionProps> = ({ children, modifiers }) => (
  <div className={mapModifiers('m-headingSection', modifiers)}>
    <span>{children}</span>
  </div>
);

HeadingSection.defaultProps = {
  modifiers: undefined,
};

export default HeadingSection;
