import * as yup from 'yup';

export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

const numberLoginSchema = yup.object({
  username: yup.string().required('Tên đăng nhập là bắt buộc!'),
  password: yup.string().required('Mật khẩu là bắt buộc!'),
});
export const registerSchema = yup.object({
  code: yup.string().required('Vui lòng nhập mã cửa hàng'),
});
export const checkInSchema = yup.object({
  startDate: yup.object().required('Vui lòng chọn ngày'),
  place: yup.object().nullable().required('Vui lòng chọn đầu cầu'),
});
export const phoneSchema = yup.object({
  phone: yup.string().required('Số điện thoại là bắt buộc!').matches(phoneRegExp, 'Không đúng định dạng'),
});

export default numberLoginSchema;
