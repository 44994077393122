/* eslint-disable no-console */
import { useEffect } from 'react';

function useMutedAutoplay(
  playerRef: React.RefObject<HTMLVideoElement>,
  callback?: (muted: boolean) => void,
  isNotAuto?: boolean,
) {
  useEffect(() => {
    const player = playerRef.current;

    if (!player || isNotAuto) {
      return;
    }

    player
      .play()
      .then(() => {
        console.log('Autoplay: unmuted');
      })
      .catch(async () => {
        player.muted = true;
        callback?.(true);

        await player.play();
        console.log('Autoplay: muted');
      })
      .catch((err) => {
        console.log('Autoplay: deined', err);
      });
  }, [playerRef]);
}
export default useMutedAutoplay;
