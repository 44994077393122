import React from 'react';

import Heading from 'components/atoms/Heading';
import Icon, { IconName } from 'components/atoms/Icon';
import Text from 'components/atoms/Text';

export interface CardNewsTypes {
  date: string;
  informations?: Array<{
    icon:IconName;
    content: string;
  }>;
}

const CardNews: React.FC<CardNewsTypes> = ({ date, informations }) => (
  <div className="m-cardnews">
    <div className="m-cardnews_wrapper">
      <div className="m-cardnews_dateGroup">
        <div className="m-cardnews_titleDate">
          <Heading type="h3" modifiers={['jet', '400']}>
            Ngày
          </Heading>
        </div>
        <div className="m-cardnews_date">
          <Heading type="h3" modifiers={['pigment', '700']}>
            {date}
          </Heading>
        </div>
      </div>
      {informations && informations.length > 0 && (
        <div className="m-cardnews_content">
          {informations.map(({ icon, content }, idx) => (
            <div className="m-cardnews_item" key={`i-${idx.toString()}`}>
              <Icon iconName={icon} />
              <div className="m-cardnews_description_content">
                <Text
                  modifiers={['400', 'pigment']}
                  content={content}
                  type="div"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);

CardNews.defaultProps = {
};

export default CardNews;
