/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Player from 'components/organisms/Player';
import eventConfigs from 'configs';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import SLUG_PARAMS from 'utils/constants';

interface IntroductionTemplateProps {
}

const IntroductionTemplate: React.FC<IntroductionTemplateProps> = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showButton, setShowButton] = useState(false);
  const [muted, setMuted] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 5000);
  }, []);

  useMutedAutoplay(videoRef, setMuted);

  return (
    <div className="t-introduction">
      <div className="t-introduction_video">
        <Player
          isMuted={muted}
          src={eventConfigs.introVideo}
          ref={videoRef}
          isLoop
          autoplay
        />
      </div>
      <div className="t-introduction_sound" onClick={() => setMuted(!muted)}>
        <Icon iconName={muted ? 'mute' : 'sound'} iconSize="32x32" />
      </div>
      {
        showButton
        && (
          <div className="t-introduction_button">
            <Button
              size="sm"
              useLink
              href={SLUG_PARAMS.REGISTER}
            >
              ĐĂNG KÝ
            </Button>
          </div>
        )
      }
    </div>
  );
};

IntroductionTemplate.defaultProps = {
};

export default IntroductionTemplate;
