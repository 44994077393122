import React from 'react';

import ForgetPasswordContainer from 'containers/ForgotPassword';

const ForgetPassword: React.FC = () => (
  <div className="p-forgetPassword">
    <ForgetPasswordContainer />
  </div>
);

export default ForgetPassword;
