import {
  CheckInByPhoneParamsTypes, CheckInParamseTypes, CheckInUserInfoTypes, EventTimesTypes,
} from './type';

import axiosInstance from 'services/common/instance';

const getEventTimesService = async (): Promise<EventTimesTypes[]> => {
  const res = await axiosInstance.get('event-times');
  return res.data.data;
};

export const getPlaceService = async (params: string): Promise<string[]> => {
  const res = await axiosInstance.get(`dau-cau?event_time_code=${params}`);
  return res.data.data;
};

export const getCheckInByPhone = async (
  params:CheckInByPhoneParamsTypes,
): Promise<CheckInUserInfoTypes> => {
  const paramsString = `check-in/member/by-phone?event_time_code=${params.event_time_code}&dau_cau=${params.dau_cau}&phone=${params.phone}`;
  const res = await axiosInstance.get(paramsString);

  return res.data.data;
};

export const requestCheckCodeService = async (
  params:CheckInByPhoneParamsTypes,
): Promise<CheckInUserInfoTypes> => {
  const paramsString = `check-in/member/by-code?event_time_code=${params.event_time_code}&dau_cau=${params.dau_cau}&shop_code=${params.shop_code}`;

  const res = await axiosInstance.get(paramsString);
  return res.data.data;
};

export const requestCheckInService = async (params: CheckInParamseTypes) => {
  const res = await axiosInstance.post('members/check-in', params);
  return res.data.data;
};

export default getEventTimesService;
