/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import NumberLogin, { NumberLoginFormTypes } from 'components/templates/NumberLogin';
import { useAsync } from 'hooks/useAsync';
import { LoginMemberParams } from 'services/member/types';
import { loginAction } from 'store/auth';
import { useAppDispatch } from 'store/hooks';
import { getMeAction } from 'store/me';
import SLUG_PARAMS from 'utils/constants';
import numberLoginSchema from 'utils/schemas';

const NUmberLoginContainer: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loadingMe, setLoadingMe] = useState(false);

  const method = useForm<NumberLoginFormTypes>({
    resolver: yupResolver(numberLoginSchema),
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  // const [loginExecute, exportState] = useAsync(
  //   (data: LoginMemberParams) => dispatch(loginAction(data)).unwrap()
  //     .then(() => {
  //       setLoadingMe(true);
  //       dispatch(getMeAction()).unwrap();
  //       setLoadingMe(false);
  //     })
  //     .catch((err) => {
  //       const errors = err as ErrorResponse[];
  //       if (errors.length) {
  //         switch (errors[0].code) {
  //           case 'E_1005':
  //             method.setError('password', { message: errors[0].title });
  //             break;
  //           default:
  //             method.setError('username', { message: 'Tài khoản chưa được đăng ký' });
  //         }
  //       }
  //     }),
  // );

  const onSubmit = async (data: NumberLoginFormTypes) => {
    setLoadingMe(true);
    const params = {
      account: data.username,
      password: data.password,
    };
    try {
      await dispatch(loginAction(params)).unwrap().then(() => {
        dispatch(getMeAction()).unwrap().then(() => window.location.reload());
      });
    } catch (err) {
      const errors = err as ErrorResponse[];
      if (errors.length) {
        switch (errors[0].code) {
          case 'E_1005':
            method.setError('password', { message: errors[0].title });
            break;
          default:
            method.setError('username', { message: 'Tài khoản chưa được đăng ký' });
        }
      }
    } finally {
      setLoadingMe(false);
    }
  };

  return (
    <NumberLogin
      forgetLink={SLUG_PARAMS.FORGET_PASSWORD}
      method={method}
      handleSubmit={onSubmit}
      loading={loadingMe}
    />
  );
};

export default NUmberLoginContainer;
