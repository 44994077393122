import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import OtpPopup from '../OtpPopup';
import PolicyPopup from '../PolicyPopup';
import SuccessPopup from '../SuccessPopup';

import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import HeadingSection from 'components/molecules/HeadingSection';
import SectionTitle from 'components/molecules/SectionTitle';
import Form from 'components/organisms/Form';
import useDebounceInput from 'hooks/useDebounceInput';
import postShopRegister, { resendOtpService, verifyOtpService } from 'services/register';
import getShopService from 'services/shop';
import SLUG_PARAMS from 'utils/constants';
import mapModifiers from 'utils/functions';
import { registerSchema } from 'utils/schemas';

export type RegisterFormDataTypes = {
  code: string;
}
interface RegisterFormProps {
  modifiers?: string;
 }

const RegisterForm: React.FC<RegisterFormProps> = ({ modifiers }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [otpError, setOtpError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [checkboxMessage, setCheckboxMessage] = useState('');
  const [checked, setChecked] = useState(true);
  const [validCode, setValidCode] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const history = useHistory();
  const [eventTime, setEventTime] = useState<string>();
  const [eventTime2, setEventTime2] = useState<string>();
  const [openPopup, setOpenPopup] = useState({
    policy: false,
    otp: false,
    success: false,
  });

  const method = useForm<RegisterFormDataTypes>({
    resolver: yupResolver(registerSchema),
    mode: 'onChange',
    defaultValues: {
      code: '',
    },
  });

  const validateField = async (
    value: string,
  ) => {
    setErrorMessage('');
    if (value) {
      const data: RegisterFormDataTypes = { code: value };
      try {
        const res = await getShopService(data.code);
        setPhone(res.phone);
        setName(res.name);
        setValidCode(true);
      } catch (error) {
        const errors = error as ErrorResponse[];

        if (Array.isArray(errors)) {
          errors.forEach((err) => {
            switch (err.code) {
              case 'E_1001':
                setErrorMessage('Mã cửa hàng đã được đăng ký');
                break;
              case 404:
                setErrorMessage('Mã cửa hàng không tồn tại');
                break;

              default:
                setErrorMessage('Mã cửa hàng không chính xác!');
                break;
            }
          });
        } else {
          setErrorMessage('Mã cửa hàng không chính xác!');
        }

        setPhone('');
        setName('');
        setValidCode(false);
      }
    }
  };

  const shopCodeDb = useDebounceInput(method.watch('code'), 500);
  useEffect(() => {
    validateField(shopCodeDb);
  }, [shopCodeDb]);

  const handleSubmit = async (data: RegisterFormDataTypes) => {
    if (!validCode) return;
    setIsLoading(true);
    setErrorMessage('');
    try {
      await postShopRegister(data.code);
      setOpenPopup((prevState) => ({
        ...prevState,
        otp: true,
      }));
    } catch (error) {
      const errors = error as ErrorResponse[];
      if (Array.isArray(errors)) {
        errors.forEach((err) => {
          if (err.code === 'E_1002') {
            setErrorMessage('Gửi SMS không thành công');
          } else setErrorMessage('Có lỗi xảy ra! Vui lòng thử lại!');
        });
      } else setErrorMessage('Có lỗi xảy ra! Vui lòng thử lại!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitOTP = async (val: string) => {
    try {
      setIsLoadingOtp(true);
      setOtpError('');
      const res = await verifyOtpService({
        code: shopCodeDb,
        otpCode: {
          otp: val,
        },
      });
      setEventTime(res.eventTime);
      setEventTime2(res.text_show);
      setOpenPopup((prevState) => ({
        ...prevState,
        otp: false,
        success: true,
      }));
      method.reset();
      setPhone('');
      setName('');
    } catch (error) {
      const errors = error as ErrorResponse[];
      if (Array.isArray(errors)) {
        errors.forEach((err) => {
          if (err.code === 404) {
            setOtpError('Mã OTP không chính xác! Vui lòng thử lại!');
          } else setOtpError('Có lỗi xảy ra! Vui lòng thử lại!');
        });
      } else setOtpError('Có lỗi xảy ra! Vui lòng thử lại!');
    } finally {
      setIsLoadingOtp(false);
    }
  };
  const handleResetOtp = async () => {
    await resendOtpService(shopCodeDb);
  };

  // Check policy checkbox status
  useEffect(() => {
    if (checked) {
      setValidCode(true);
      setCheckboxMessage('');
    } else {
      setValidCode(false);
      setCheckboxMessage('Vui lòng chọn đồng ý điều khoản chương trình!');
    }
  }, [checked]);

  useEffect(() => {
    if (openPopup.policy) {
      document.documentElement.classList.add('overflow');
    } else document.documentElement.classList.remove('overflow');
  }, [openPopup.policy]);

  return (
    <>
      <div className={mapModifiers('t-registerForm', modifiers)}>
        <SectionTitle>
          <div className="t-registerForm_wrapper">
            <div className="t-registerForm_wrapper_title">
              <HeadingSection modifiers="secondary">
                đăng ký tham gia
              </HeadingSection>
            </div>
            <div className="t-registerForm_title">
              <Text modifiers={['md', 'pigment', '400', 'center']}>
                Quý khách hàng vui lòng điền mã cửa hàng được dán trên thiệp mời
                vào ô bên dưới
              </Text>
            </div>
            <Form method={method} submitForm={handleSubmit}>
              <div className="t-registerForm_formWrapper">
                <div className="t-registerForm_formWrapper-field">
                  <Controller
                    name="code"
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        id="code"
                        label="Nhập mã cửa hàng"
                        value={field.value}
                        error={fieldState?.error?.message}
                          // handleBlur={(e) => validateField(
                          //   e.currentTarget.value,
                          // )}
                        handleChange={field.onChange}
                      />
                    )}
                  />
                  {
                      errorMessage !== ''
                      && (
                        <div className="t-registerForm_error">
                          {errorMessage}
                        </div>
                      )
                    }
                </div>
                <div className="t-registerForm_noti">
                  <Text modifiers={['md', 'pigment', 'center', '400']}>
                    Vui lòng kiểm tra thông tin tên cửa hàng và số điện thoại
                  </Text>
                </div>
                <div className="t-registerForm_formWrapper-field t-registerForm_formWrapper-field-mb">
                  <Input
                    id="name"
                    noCursor
                    value={name}
                    handleChange={(e) => setName(e.target.value)}
                    label="Tên cửa hàng"
                  />
                </div>
                <div className="t-registerForm_formWrapper-field">
                  <Input
                    id="phone"
                    noCursor
                    value={phone}
                    handleChange={(e) => setPhone(e.target.value)}
                    label="Số điện thoại"
                  />
                </div>
                <div className="t-registerForm_term">
                  <Text modifiers={['md', 'pigment', 'center', '400']}>
                    Nếu các thông tin trên chính xác, vui lòng nhấn vào nút ĐĂNG
                    KÝ dưới đây
                  </Text>
                  <div className="t-registerForm_term_checkbox">
                    <Checkbox
                      id="term"
                      checked={checked}
                      name="term"
                      error={checkboxMessage}
                      onChange={() => setChecked(!checked)}
                    >
                      <Text modifiers={['md', 'pigment']}>
                        Tôi đã đọc và đồng ý với điều khoản lưu trữ thông tin
                      </Text>
                    </Checkbox>
                  </div>
                  <div className="t-registerForm_term-button">
                    <Button
                      loading={isLoading}
                      type="submit"
                    >
                      ĐĂNG KÝ
                    </Button>
                  </div>
                </div>
                <div
                  onClick={() => setOpenPopup((prevState) => ({
                    ...prevState,
                    policy: true,
                  }))}
                  className="t-registerForm_title t-registerForm_title-bg"
                >
                  <Text
                    modifiers={[
                      'md',
                      'pigment',
                      'underline',
                      '400',
                      'center',
                    ]}
                  >
                    Xem thêm về điều khoản lưu trữ thông tin của chương trình
                  </Text>
                </div>
              </div>
            </Form>
          </div>
        </SectionTitle>
      </div>
      <PolicyPopup
        openPopup={openPopup.policy}
        handleClose={() => setOpenPopup((prevState) => ({
          ...prevState,
          policy: false,
        }))}
      />
      <OtpPopup
        loading={isLoadingOtp}
        openPopup={openPopup.otp}
        error={otpError}
        phoneValue={phone}
        handleResend={handleResetOtp}
        handleSubmit={handleSubmitOTP}
        handleClose={() => setOpenPopup((prevState) => ({
          ...prevState,
          otp: false,
        }))}
      />
      <SuccessPopup
        // account={{
        //   name: 'asdf',
        //   password: '21298012',
        // }}
        eventTime={eventTime}
        eventTime2={eventTime2}
        openPopup={openPopup.success}
        handleClose={() => {
          setOpenPopup((prevState) => ({
            ...prevState,
            success: false,
          }));
          if (modifiers === 'secondary') {
            setTimeout(() => {
              history.push(SLUG_PARAMS.NUMBER_LOGIN);
            }, 500);
          }
        }}
      />
    </>
  );
};
RegisterForm.defaultProps = {
  modifiers: undefined,
};

export default RegisterForm;
