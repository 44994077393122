import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import HeadingSection from 'components/molecules/HeadingSection';
import NumberBannerLayout from 'components/organisms/NumberBannerLayout';
import { getPrizesService } from 'services/picking';
import { useAppSelector } from 'store';
import { numberWithCommas } from 'utils/functions';

interface NumberWelcomeProps {
  urlIntro?: string;
}

const NumberWelcome: React.FC<NumberWelcomeProps> = ({
  urlIntro,
}) => {
  const history = useHistory();
  const { data, loading: isLoading } = useAppSelector((state) => state.me);
  const [dataPrize, setDataPrize] = useState<{label: string; value: string}[]>();

  useEffect(() => {
    (async () => {
      const res = await getPrizesService();
      // PRIZE LIST DATA MODIFIER
      setDataPrize(res.map((val) => {
        const awardPrize = val.award ? numberWithCommas(parseInt(val.award, 10)) : 0;
        const numberPrize = val.number_prize >= 10 ? val.number_prize : `0${val.number_prize}`;
        return {
          label: `${numberPrize} ${val.name}`,
          value: `${awardPrize.toString()} VNĐ/Giải`,
        };
      }));
    })();
  }, []);

  if (isLoading) return <p>Loading</p>;
  return (
    <div className="t-numberWelcome">
      <NumberBannerLayout>
        <div className="t-numberWelcome_panel">
          <div className="t-numberWelcome_panel_border">
            <div className="t-numberWelcome_heading">
              <Heading type="h2" modifiers={['jet', 'uppercase', 'center', '700', 'md']}>
                XIN KÍNH CHÀO QUÝ KHÁCH
              </Heading>
              <Heading type="h3" modifiers={['pigment', 'uppercase', 'center', '700', 'md']}>
                {data?.name}
              </Heading>
              <Text modifiers={['16x22', 'jet', 'center', '700']}>
                Quý khách vui lòng đọc kĩ thể lệ chọn số trước khi chọn số dự thưởng
              </Text>
            </div>
            <div className="t-numberWelcome_label">
              <HeadingSection modifiers="secondary">
                THỂ LỆ CHỌN SỐ
              </HeadingSection>
            </div>

            {/* RULE  */}
            <div className="t-numberWelcome_rule">
              <div className="t-numberWelcome_rule_item">
                <Text modifiers={['jet', 'sm', '400']}>
                  Mỗi tài khoản phải chọn tất cả các số dự thưởng trong 1 lượt đăng nhập duy nhất.
                </Text>
              </div>
              <div className="t-numberWelcome_rule_item">
                <Text modifiers={['jet', 'sm', '400']}>
                  Hệ thống chỉ giữ số dự thưởng của Quý khách trong thời gian 20 phút,
                  vui lòng bấm nút
                  {' '}
                  <Text type="span" modifiers={['jet', 'sm', '700']}>
                    XÁC NHẬN CHỌN SỐ
                  </Text>
                  {' '}
                  ngay khi thực hiện thao tác chọn xong
                  tất cả các số dự thưởng. Nếu sau thời gian này, quý khách chưa chọn xong
                  và chưa bấm nút
                  {' '}
                  <Text type="span" modifiers={['jet', 'sm', '700']}>
                    XÁC NHẬN CHỌN SỐ
                  </Text>
                  , hệ thống sẽ tự động yêu cầu
                  chọn tất cả số dự thưởng lại từ đầu.
                </Text>
              </div>
              <div className="t-numberWelcome_rule_item">
                <Text modifiers={['jet', 'sm', '400']}>
                  Quý khách sẽ không được thay đổi số dự thưởng đã chọn sau khi hoàn thành
                  các bước chọn số.
                </Text>
              </div>
              <div className="t-numberWelcome_rule_item">
                <Text modifiers={['jet', 'sm', '400']}>
                  Quý khách có thể theo dõi chương trình quay hình trực tuyến
                  bỏ banh vào lồng cầu sẽ được diễn ra tại trang web
                  {' '}
                  <Link href="https://hoinghikhachhangclp.com">
                    <Text type="span" modifiers={['pigment', 'sm', '700', 'underline']}>
                      https://hoinghikhachhangclp.com
                    </Text>
                  </Link>
                </Text>
              </div>
              <div className="t-numberWelcome_rule_item">
                <Text modifiers={['jet', 'sm', '400']}>
                  Giá trị giải thưởng:
                </Text>
                {
                  dataPrize
                  && (
                  <div className="special">
                    <div className="label">
                      <Text modifiers={['jet', 'sm', '700']}>
                        &#8226;
                        {' '}
                        {dataPrize[0].label}
                      </Text>
                    </div>
                    <div className="value">
                      <Text modifiers={['jet', 'sm', '700']}>
                        {dataPrize[0].value}
                      </Text>
                    </div>
                  </div>
                  )
                }
                <ul>
                  {
                    dataPrize && dataPrize.slice(1, dataPrize.length).map((val, idx) => (
                      <li key={`i-${idx.toString()}`}>
                        <div className="label">
                          <Text modifiers={['jet', 'sm', '400']}>
                            &#8226;
                            {' '}
                            {val.label}
                          </Text>
                        </div>
                        <div className="value">
                          <Text modifiers={['jet', 'sm', '400']}>
                            {val.value}
                          </Text>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div className="t-numberWelcome_rule_item link">
                <Text modifiers={['jet', 'sm', '400']}>
                  Mọi thắc mắc xin liên hệ tổng đài:
                  {' '}
                  <Link href="tel:0918454049" useExternal>
                    <Text type="span" modifiers={['jet', 'sm', '700']}>
                      0918 45 40 49
                    </Text>
                  </Link>
                </Text>
              </div>
            </div>
            {/* BUTTON  */}
            <div className="t-numberWelcome_button">
              <Button
                size="sm"
                handleClick={() => history.push(urlIntro || '/')}
              >
                TIẾP TỤC HƯỚNG DẪN
              </Button>
            </div>
          </div>
        </div>
      </NumberBannerLayout>
    </div>
  );
};

NumberWelcome.defaultProps = {
  urlIntro: undefined,
};

export default NumberWelcome;
