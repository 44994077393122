import abbottBooth from 'assets/images/foyer/booth-abbott.png';
import ensureBooth from 'assets/images/foyer/booth-ensure.png';
import glucernaBooth from 'assets/images/foyer/booth-glucerna.png';
import pediasureBooth from 'assets/images/foyer/booth-pediasure.png';
import similacBooth from 'assets/images/foyer/booth-similac.png';
import thumbnailBig1Img from 'assets/images/selfie/big-frame1.png';
import thumbnailBig2Img from 'assets/images/selfie/big-frame2.png';
import thumbnailBig3Img from 'assets/images/selfie/big-frame3.png';
import thumbnailBig4Img from 'assets/images/selfie/big-frame4.png';
import thumbnailBig5Img from 'assets/images/selfie/big-frame5.png';
import thumbnailBig6Img from 'assets/images/selfie/big-frame6.png';
import captureButtonImg from 'assets/images/selfie/capture-btn.png';
import saveButtonImg from 'assets/images/selfie/download-btn.png';
import nextButtonImg from 'assets/images/selfie/next-btn.png';
import recapButtonImg from 'assets/images/selfie/recap-btn.png';
import thumbnailSmall1Img from 'assets/images/selfie/small-frame1.png';
import thumbnailSmall2Img from 'assets/images/selfie/small-frame2.png';
import thumbnailSmall3Img from 'assets/images/selfie/small-frame3.png';
import thumbnailSmall4Img from 'assets/images/selfie/small-frame4.png';
import thumbnailSmall5Img from 'assets/images/selfie/small-frame5.png';
import thumbnailSmall6Img from 'assets/images/selfie/small-frame6.png';
import { MenuFoyerTypes } from 'components/templates/Foyer/menu';

const buttonStyle = {
  nextButton: nextButtonImg,
  captureButton: captureButtonImg,
  recapButton: recapButtonImg,
  saveButton: saveButtonImg,
};

export const frameSmallImg = [
  {
    id: 1,
    frame: thumbnailSmall1Img,
  },
  {
    id: 2,
    frame: thumbnailSmall2Img,
  },
  {
    id: 3,
    frame: thumbnailSmall3Img,
  },
  {
    id: 4,
    frame: thumbnailSmall4Img,
  },
  {
    id: 5,
    frame: thumbnailSmall5Img,
  },
  {
    id: 6,
    frame: thumbnailSmall6Img,
  },
];
export const bigSelfieFrame = [
  {
    id: 1,
    frame: thumbnailBig1Img,
  },
  {
    id: 2,
    frame: thumbnailBig2Img,
  },
  {
    id: 3,
    frame: thumbnailBig3Img,
  },
  {
    id: 4,
    frame: thumbnailBig4Img,
  },
  {
    id: 5,
    frame: thumbnailBig5Img,
  },
  {
    id: 6,
    frame: thumbnailBig6Img,
  },
];
export const boothLogoImg = [
  abbottBooth,
  pediasureBooth,
  similacBooth,
  ensureBooth,
  glucernaBooth,
];
export const menuDataFoyer: MenuFoyerTypes[] = [
  {
    id: 1,
    text: 'THAM QUAN SẢNH',
    code: 'SANH',
  },
  {
    id: 2,
    text: 'VÀO KHU TRIỂN LÃM',
    code: 'KHU_TRIEN_LAM',
  },
  {
    id: 3,
    text: 'VÀO PHÒNG HỘI NGHỊ',
    code: 'HOI_NGHI',
  },
];
export const mainFoyerData = {
  step: [
    {
      id: 1,
      title: 'step 1',
      video: 'https://cdn.hoinghikhachhangclp.com/static/clp-2021/hanhtrinh1.720.mp4',
    },
  ],
};

export default buttonStyle;
