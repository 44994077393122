import React from 'react';

import SelfieContainer from 'containers/Selfie';

const Test2: React.FC = () => (
  <div>
    <SelfieContainer />
  </div>
);

export default Test2;
