import React from 'react';

import InvitationCard from 'components/templates/InvitationCard';
import { useAppSelector } from 'store';

const Invitation: React.FC = () => {
  const { data: profileData } = useAppSelector((state) => state.me);

  // const socketLivestream = useCallback(() => {
  //   dispatch(getMeAction()).unwrap();
  // }, [dispatch]);

  // useEffect(() => {
  //   if (profileData) {
  //     const channel = echo.private(`EventTime.${profileData.event_time_code}`);
  //     channel.listen('updated', socketLivestream);
  //     return () => {
  //       channel.stopListening('updated', socketLivestream);
  //     };
  //   }
  //   return () => {};
  // }, [profileData, socketLivestream]);
  return (
    <div className="t-invitation">
      <InvitationCard
        profile={profileData}
      />
    </div>
  );
};

export default Invitation;
