import React from 'react';

import NUmberLoginContainer from 'containers/NumberLogin';

const NumberLogin: React.FC = () => (
  <div className="p-numberLogin">
    <NUmberLoginContainer />
  </div>
);

export default NumberLogin;
