import React from 'react';

import Button from 'components/atoms/Button';
import Popup from 'components/organisms/Popup';

interface ContextPopupProps {
  openPopup: boolean;
  handleClose?: () => void;
}

const ContextPopup: React.FC<ContextPopupProps> = ({
  openPopup,
  children,
  handleClose,
}) => (
  <div className="t-contextPopup">
    <Popup
      modifiers="context"
      isOpenModal={openPopup}
      title="THÔNG BÁO"
      handleClose={handleClose}
    >
      {children}
      <br />
      <div className="o-popup_button u-mt-30">
        <Button
          size="sm"
          variant="secondary"
          handleClick={handleClose}
        >
          ĐÓNG
        </Button>
      </div>
    </Popup>
  </div>
);

ContextPopup.defaultProps = {
  handleClose: undefined,
};

export default ContextPopup;
