import React from 'react';

import DeviceWrapped from 'components/templates/DeviceWrapped';
import PrizeContainer from 'containers/Prize';

const Prize: React.FC = () => (
  <div className="p-prize">
    <DeviceWrapped>
      <PrizeContainer />
    </DeviceWrapped>
  </div>
);

export default Prize;
