import React from 'react';

import mapModifiers from 'utils/functions';

interface ImageButtonProps {
  imgSrc: string;
  name?: string;
  disabled?: boolean;
  modifiers?: 'recap' | 'capture' | 'next' | 'save';
  handleClick?: () => void;
}

const ImageButton: React.FC<ImageButtonProps> = ({
  imgSrc, name, modifiers, disabled, handleClick,
}) => (
  <button
    type="button"
    name={name}
    className={mapModifiers('a-imageButton', modifiers, disabled && 'disabled')}
    onClick={handleClick}
  >
    <img src={imgSrc} alt={name} />
  </button>
);

ImageButton.defaultProps = {
  name: undefined,
  handleClick: undefined,
  modifiers: undefined,
  disabled: undefined,
};

export default ImageButton;
