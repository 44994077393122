/* eslint-disable react/require-default-props */
import React from 'react';

import mapModifiers from 'utils/functions';

interface TableRowProps extends React.TableHTMLAttributes<HTMLTableRowElement> {
  isHead?: boolean;
}

interface TableCellProps extends React.TableHTMLAttributes<HTMLTableCellElement> {
  isHead?: boolean;
  colSpan?:number;
  rowSpan?:number;
  classModify?: string;
}

interface TableProps {
  classModifiers?:string;
  modifiers?: ''|'transaction' | 'secondary' | 'small';
  header?: React.ReactNode;
  spacing?: boolean;
}

export const TableRow: React.FC<TableRowProps> = ({ isHead, children, ...props }) => (
  <tr className={isHead ? 'o-table_header_row' : 'o-table_body_row'} {...props}>
    {children}
  </tr>
);

export const TableCell: React.FC<TableCellProps> = ({
  isHead, children, classModify, ...props
}) => {
  const Element = isHead ? 'th' : 'td';
  return (
    <Element
      {...props}
      className={`${mapModifiers(isHead ? 'o-table_header_cell' : 'o-table_body_cell', props.onClick && 'cursor')} ${classModify || ''}`}
    >
      {children}
    </Element>
  );
};

export const TableHeader: React.FC = ({ children }) => (
  <thead className="o-table_header">
    {children}
  </thead>
);

const Table: React.FC<TableProps> = ({
  classModifiers,
  header,
  children,
  modifiers,
  spacing,
}) => (
  <div className={mapModifiers('o-table', classModifiers, modifiers, spacing && 'spacing')}>
    <table className="o-table_wrap">
      {header}
      <tbody className="o-table_body">
        {children}
      </tbody>
    </table>
  </div>
);

Table.defaultProps = {
  classModifiers: '',
  header: undefined,
  modifiers: undefined,
  spacing: undefined,
};

TableRow.defaultProps = {
  isHead: undefined,
};

TableCell.defaultProps = {
  isHead: undefined,
  colSpan: undefined,
  rowSpan: undefined,
};

export default Table;
