import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getConfigsLiveStreamService, { getConfigsFoyerService } from 'services/configs';
import { FoyerConfigData, LiveStreamConfigs } from 'services/configs/type';

interface ConfigsState {
  loading: boolean;
  livestream?: LiveStreamConfigs;
  foyer?: FoyerConfigData;
}

const initialState: ConfigsState = {
  loading: false,
};

export const getConfigsLiveStreamAction = createAsyncThunk<
  LiveStreamConfigs,
  void,
  { rejectValue: ErrorResponse }
>(
  'configs/getConfigsLiveStreamAction',
  async (_, { rejectWithValue }) => {
    try {
      const configs = await getConfigsLiveStreamService();
      return configs;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);
export const getConfigsFoyerAction = createAsyncThunk<
  FoyerConfigData,
  void,
  { rejectValue: ErrorResponse }
>(
  'configs/getConfigsFoyerAction',
  async (_, { rejectWithValue }) => {
    try {
      const configs = await getConfigsFoyerService();
      return configs;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

const configsReducer = createSlice({
  name: 'configsReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfigsLiveStreamAction.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getConfigsLiveStreamAction.fulfilled, ($state, action) => {
      $state.livestream = action.payload;
      $state.loading = false;
    });
    builder.addCase(getConfigsLiveStreamAction.rejected, ($state) => {
      $state.loading = false;
    });

    builder.addCase(getConfigsFoyerAction.fulfilled, ($state, action) => {
      $state.foyer = action.payload;
    });
  },
});

export default configsReducer.reducer;
