import React from 'react';

import DeviceWrapped from 'components/templates/DeviceWrapped';
import SpecialPrizeContainer from 'containers/Prize/special';

const PrizeSpecial: React.FC = () => (
  <div className="p-prizeSpecial">
    <DeviceWrapped>
      <SpecialPrizeContainer />
    </DeviceWrapped>
  </div>
);

export default PrizeSpecial;
