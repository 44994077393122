import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface LinkProps extends Omit<RouterLinkProps, 'to'> {
  href: string;
  search?: string;
  useExternal?: boolean;
}

const Link: React.FC<LinkProps> = ({
  children,
  href,
  useExternal,
  search,
  ...props
}) => {
  if (href.includes('http')) {
    return (
      <a {...props} href={href} className="a-link">
        {children}
      </a>
    );
  }
  if (useExternal) {
    return (
      <a {...props} href={href} className="a-link">
        {children}
      </a>
    );
  }
  return (
    <RouterLink
      className="a-link"
      {...props}
      to={{
        pathname: href,
        search,
      }}
      aria-label="label"
    >
      {children}
    </RouterLink>
  );
};

Link.defaultProps = {
  search: undefined,
  useExternal: undefined,
};

export default Link;
