/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import Finish, { FinishActionProps } from './finish';
import Processing, { SelfieProcessProps } from './process';

import buttonStyle, { bigSelfieFrame, frameSmallImg } from 'assets/data/foyer';
import backgroundImg from 'assets/images/selfie/bg-selfie.png';
import Image from 'components/atoms/Image';
import ImageButton from 'components/atoms/ImageButton';
import useWindowDimensions from 'hooks/useWindowDimension';

interface SelfieProps {
  permissionCam?: boolean;
  handleError?: () => void;
  handleNext?: () => void;
  onSave?: (imgUrl: string) => void;
}

const Selfie: React.FC<SelfieProps> = ({
  permissionCam, handleError, onSave, handleNext,
}) => {
  const [step, setStep] = useState(0);
  const [idxActiveFrame, setIdxActiveFrame] = useState(0);
  const camRef = useRef<SelfieProcessProps>(null);
  const saveImageRef = useRef<FinishActionProps>(null);
  const { width, height } = useWindowDimensions();
  const [save, setSave] = useState(false);
  const imgSrcRef = useRef<string>('');
  const [permission, setPermission] = useState<PermissionState>('prompt');
  const [resize, setResize] = useState(false);

  const handleCapture = async () => {
    if (camRef.current) {
      const res = await camRef.current.takeSelfie();
      imgSrcRef.current = res;
      setStep(1);
    }
  };

  const handleSave = async () => {
    if (saveImageRef.current) {
      if (width && width > 1199) {
        const res = await saveImageRef.current.onSave();
        const a = document.createElement('a');
        a.href = res;
        a.download = `filename-${new Date().toUTCString()}.jpg`;
        a.click();
      } else {
        setSave(true);
        setTimeout(() => {
          setSave(false);
        }, 7500);
      }
    }
  };

  useEffect(() => {
    if (width && height && width >= 1200 && height < 800) {
      setResize(true);
    } else setResize(false);
  }, [width, height]);

  return (
    <>
      <div className="t-selfie">
        <div className="t-selfie_background">
          <img src={backgroundImg} alt="background" />
        </div>
        <div className={`t-selfie_wrap${resize ? ' resize' : ''}`}>
          <div className="t-selfie_action">
            <div className="t-selfie_action_item">
              <ImageButton
                disabled={step === 0}
                name="recap"
                modifiers="recap"
                handleClick={() => setStep(0)}
                imgSrc={buttonStyle.recapButton}
              />
            </div>
            <div className="t-selfie_action_item">
              {
              step !== 0 ? (
                <ImageButton
                  name="save"
                  modifiers="save"
                  handleClick={handleSave}
                  imgSrc={buttonStyle.saveButton}
                />
              ) : (
                <ImageButton
                  disabled={permission !== 'granted'}
                  name="capture"
                  modifiers="capture"
                  handleClick={handleCapture}
                  imgSrc={buttonStyle.captureButton}
                />
              )
            }
            </div>
            <div className="t-selfie_action_item">
              <ImageButton
                name="next"
                modifiers="next"
                handleClick={handleNext}
                imgSrc={buttonStyle.nextButton}
              />
            </div>
          </div>
          <div className="t-selfie_frame">
            {
            step === 0
            && (
              <div className="t-selfie_border">
                <div className="t-selfie_border_title">
                  NHẤN CHỌN
                  <span>KHUNG HÌNH</span>
                </div>
                <div className="t-selfie_border_list">
                  {
                    frameSmallImg.map((val, idx) => (
                      <div
                        onClick={() => setIdxActiveFrame(idx)}
                        className={`t-selfie_border_list_item${idxActiveFrame === idx ? ' active' : ''}`}
                        key={`item-${idx.toString()}`}
                      >
                        <Image imgSrc={val.frame} ratio="142x83" size="cover" alt="thumbnail" />
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          }
            {
            (() => {
              switch (step) {
                case 0:
                  return (
                    <Processing
                      ref={camRef}
                      handlePermission={(val) => {
                        setPermission(val);
                      }}
                      borderImg={bigSelfieFrame[idxActiveFrame].frame}
                    />
                  );
                case 1:
                  return (
                    <Finish
                      ref={saveImageRef}
                      hasSave={save}
                      backgroundTransparent={bigSelfieFrame[idxActiveFrame].frame}
                      imgUrl={imgSrcRef.current}
                    />
                  );

                default:
                  return null;
              }
            })()
          }
          </div>
        </div>
      </div>

    </>
  );
};

Selfie.defaultProps = {
  handleError: undefined,
  handleNext: undefined,
  onSave: undefined,
  permissionCam: undefined,
};

export default Selfie;
