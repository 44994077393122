import React from 'react';

import mapModifiers from 'utils/functions';

interface ContainerProps {
  fullScreen?: boolean;
  noPaddingRight?: boolean;
  noPaddingLeft?: boolean;
  pdSize?: 'pd-sm' | 'pd-lg';
  className?: string;
}

const Container: React.FC<ContainerProps> = ({
  fullScreen,
  noPaddingRight,
  noPaddingLeft,
  children,
  pdSize,
  className,
}) => (
  <div
    className={`container ${mapModifiers(
      'o-container',
      fullScreen && 'fullscreen',
      noPaddingRight && 'noPaddingRight',
      noPaddingLeft && 'noPaddingLeft',
      pdSize,
    )}${className ? ` ${className}` : ''}`}
  >
    {children}
  </div>
);

Container.defaultProps = {
  fullScreen: false,
  noPaddingRight: false,
  noPaddingLeft: false,
  pdSize: undefined,
};

export default Container;
