import React from 'react';

import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import SectionTitle from 'components/molecules/SectionTitle';
import Container from 'components/organisms/Container';

interface ShowContentProps {
  title: string
  headerData: string[];
  rowData: {
    time: string;
    content: string;
  }[];
}

const ShowContent: React.FC<ShowContentProps> = ({
  title,
  headerData,
  rowData,
}) => (
  <div className="t-showContent">
    <Container>
      <SectionTitle title={title}>
        <div className="t-showContent_wrapper">
          <table className="t-showContent_table">
            <tr className="t-showContent_row t-showContent_row-header">
              {headerData.map((item, idx) => (
                <th
                  className="t-showContent_cell"
                  key={`header-${idx.toString()}`}
                >
                  <Heading
                    type="h3"
                    modifiers={['lg', 'pigment', '700', 'center']}
                  >
                    {item}
                  </Heading>
                </th>
              ))}
            </tr>
            {rowData.map((item, i) => (
              <tr className="t-showContent_row" key={`row-${i.toString()}`}>
                <td className="t-showContent_cell time">
                  <Text modifiers={['md-sm', 'pigment', '400']}>
                    {item.time}
                  </Text>
                </td>
                <td className="t-showContent_cell">
                  <Text modifiers={['md-sm', 'pigment', '400']}>
                    {item.content}
                  </Text>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </SectionTitle>
    </Container>
  </div>
);

ShowContent.defaultProps = {
};

export default ShowContent;
