import React, {
  forwardRef, useEffect, useImperativeHandle, useMemo, useState,
} from 'react';

import Text from 'components/atoms/Text';
import {
  dataURLtoFile, getRandomArbitrary, imageLoader,
} from 'utils/functions';

export interface FinishActionProps {
  onSave: () => string;
}

interface FinishProps {
  backgroundTransparent: string;
  imgUrl: string;
  imgEdit?: string;
  // onBack?: () => void;
  // onUpload?: () => void;
  // onSave?: (url: string) => void;
  hasSave?: boolean;
}

const Finish = forwardRef<FinishActionProps, FinishProps>((
  {
    backgroundTransparent, hasSave,
    imgUrl, imgEdit,
  }, ref,
) => {
  const WIDTH = 1200;
  const HEIGHT = 900;
  const [imgSrc, setImgSrc] = useState('');
  const [background, setBackground] = useState<HTMLImageElement>();
  const [bgImgSrc, setBgImgSrc] = useState<HTMLImageElement>();

  const canvas = useMemo(() => {
    const c = document.createElement('canvas');
    c.width = WIDTH;
    c.height = HEIGHT;

    return c;
  }, []);

  const ctx = useMemo(() => canvas.getContext('2d')!, [canvas]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      if (!backgroundTransparent) {
        return;
      }
      console.time('bg');
      const bg = await imageLoader(backgroundTransparent);
      console.timeEnd('bg');
      if (mounted) setBackground(bg);
    })();

    return () => {
      mounted = false;
    };
  }, [backgroundTransparent]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      if (!imgUrl) {
        return;
      }
      console.time('cam');
      const bg = await imageLoader(imgUrl);
      console.timeEnd('cam');

      if (mounted) setBgImgSrc(bg);
    })();

    return () => {
      mounted = false;
    };
  }, [imgUrl]);

  useEffect(() => {
    if (!background || !bgImgSrc) {
      return;
    }

    const ratio = 878 / 659; // r = w/h

    const { width: imgWidth, height: imgHeight } = bgImgSrc;

    const imgCropWidth = Math.min(
      imgWidth,
      imgHeight * ratio,
    );
    const imgCropHeight = Math.min(
      imgHeight,
      imgWidth / ratio,
    );

    ctx.drawImage(
      bgImgSrc,
      (imgWidth - imgCropWidth) / 2, (imgHeight - imgCropHeight) / 2,
      imgCropWidth, imgCropHeight,
      0, 0, WIDTH, HEIGHT,
    );

    ctx.drawImage(background, 0, 0, WIDTH, HEIGHT);

    setImgSrc(canvas.toDataURL('image/jpeg'));
  }, [imgUrl, bgImgSrc, background, ctx, canvas]);

  useEffect(() => {
    if (imgSrc || imgEdit) {
      (async () => {
        try {
          const formData = new FormData();
          const imgFile = dataURLtoFile(imgEdit || imgSrc, `clp-img${getRandomArbitrary(0, 1000)}`);
          formData.append('photo', imgFile);
          // const res = await uploadPhotoSharing(formData);

          // setUrlStared(res.data.data.url);
        } catch (error) {
          // empty
        }
      })();
    }
  }, [imgSrc, imgEdit]);

  useImperativeHandle(ref, () => ({
    onSave: () => {
      if (imgSrc) return imgSrc;
      return '';
    },
  }));

  return (
    <div className="t-selfie_frame_video hotfix">
      <div className="t-selfie_frame_image">
        {hasSave && (
        <div className="tag-save">
          <Text modifiers={['pigment', '14x19', 'center']}>
            BẤM GIỮ ẢNH ĐỂ TẢI VỀ
          </Text>
        </div>
        )}
        <img alt="clp" src={imgEdit || imgSrc || backgroundTransparent} crossOrigin="anonymous" />
      </div>
    </div>
  );
});

Finish.defaultProps = {
  imgEdit: undefined,
  // onBack: undefined,
  // onUpload: undefined,
  // onSave: undefined,
  hasSave: undefined,
};

export default Finish;
