import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { clearTokenSession } from 'services/common/storage';
import { getProfileService } from 'services/member';
import { GetMemberResponse } from 'services/member/types';

interface MeState {
  data?: GetMemberResponse;
  loading?: boolean;
  error?: boolean;
  hasDone?: boolean;
}

const initialState: MeState = {
  data: undefined,
  error: undefined,
  loading: false,
  hasDone: false,
};

export const getMeAction = createAsyncThunk<
  GetMemberResponse,
  void,
  { rejectValue: ErrorResponse }
>(
  'ME', async (_, { rejectWithValue }) => {
    try {
      const res = await getProfileService();
      return res.data;
    } catch (error) {
      clearTokenSession();
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

export const meSlice = createSlice({
  name: 'meReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getMeAction.pending, ($state) => {
      $state.loading = true;
      $state.hasDone = false;
    });
    builder.addCase(getMeAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
      $state.loading = false;
      $state.error = false;
      $state.hasDone = true;
    });
    builder.addCase(getMeAction.rejected, ($state) => {
      $state.loading = false;
      $state.error = true;
      $state.hasDone = true;
    });
  },
});

export default meSlice.reducer;
