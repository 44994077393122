import React, { useEffect } from 'react';

import useCountdown from 'hooks/useCountdown';

export type TimeTypes = {
  minutes: number;
  seconds: number;
}
interface CountdownProps {
  timer: number;
  handleTimeout?: () => void;
  handleDuration?: (time: TimeTypes) =>void;
}

const Countdown: React.FC<CountdownProps> = ({ timer, handleTimeout, handleDuration }) => {
  const endTime = new Date().getTime() + 60000 * timer;
  const [timeLeft] = useCountdown(endTime, handleTimeout);

  const minutes = Math.floor(parseInt(timeLeft.toString(), 10) / 60000) % 60;
  const seconds = Math.floor(parseInt(timeLeft.toString(), 10) / 1000) % 60;
  useEffect(() => {
    if (minutes && seconds && handleDuration) {
      handleDuration({
        minutes, seconds,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, seconds]);

  return (
    <span className="m-countdown">
      {' '}
      {minutes < 10 ? `0${minutes}` : minutes}
      {' '}
      phút
      {' '}
      {seconds < 10 ? `0${seconds}` : seconds}
      {' '}
      giây
    </span>
  );
};

Countdown.defaultProps = {
  handleTimeout: undefined,
  handleDuration: undefined,
};

export default Countdown;
