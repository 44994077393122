import emojiCare from 'assets/icons/emoji-care.png';
import emojiHaha from 'assets/icons/emoji-haha.png';
import emojiLike from 'assets/icons/emoji-like.png';
import emojiLove from 'assets/icons/emoji-love.png';
import emojiWow from 'assets/icons/emoji-wow.png';
import { EmojiIconTypes } from 'components/organisms/Emoji';

const emojiData: EmojiIconTypes[] = [
  {
    id: 1,
    name: 'like',
    icon: emojiLike,
    count: 0,
  },
  {
    id: 2,
    name: 'love',
    icon: emojiLove,
    count: 0,
  },
  {
    id: 3,
    name: 'care',
    icon: emojiCare,
    count: 0,
  },
  {
    id: 4,
    name: 'haha',
    icon: emojiHaha,
    count: 0,
  },
  {
    id: 5,
    name: 'wow',
    icon: emojiWow,
    count: 0,
  },
];
export default {
  emojiData,
  introVideo: 'https://cdn.hoinghikhachhangclp.com/static/clp-2022/clipteaser.720.mp4',
  landingpageVideo: 'https://cdn.3forcom.org/static/clp-2023/clipguideline.1080.mp4',
  finishVideo: 'https://cdn.hoinghikhachhangclp.com/static/clp-2022/placeholder.mp4',
};
