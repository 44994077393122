/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react';
import ReactSlick, { CustomArrowProps, Settings } from 'react-slick';

import SearchText from 'assets/images/ball/search.svg';
import Title from 'assets/images/ball/title.svg';
import loadingImg from 'assets/images/loading-ball.gif';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Ball, { BallVariantTypes } from 'components/molecules/Ball';
import Countdown, { TimeTypes } from 'components/molecules/Countdown';
import Container from 'components/organisms/Container';
import useIsMobile from 'hooks/useDeviceQueries';
import useWindowDimensions from 'hooks/useWindowDimension';
import mapModifiers, { addZeroPrefixNumberPositive } from 'utils/functions';

export interface BallType {
  value: string;
  variant: BallVariantTypes;
  visible?: boolean;
}

interface PickingBallProps {
  username: string;
  activeBall?: Array<BallType>;
  ball: Array<BallType>;
  isError?: boolean;
  checkFilter?: boolean;
  limitBall: number;
  timer?: number;
  resize?: boolean;
  searchValue?: string;
  keySlide?: number;
  loadingPicking?: boolean;
  handleClick?: (val?: BallType) => void;
  handleChange?: (val: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: () => void;
  handleShowAll?: () => void;
  handleTimeout?: () => void;
  handleDuration?: (time: TimeTypes) => void;
  handleCheck?: ()=> void;
}

interface DescriptionProps {
  idx: number;
  currentBall?: number;
  limitBall?: number;
  giftLimit?: number;
  giftCurrent?: number;
  handleTimeout?: () => void;
}

interface ArrowProps extends CustomArrowProps {
  onClick?: () => void;
}

const PrevArrow: React.FC<ArrowProps> = ({ onClick }) => (
  <div className="slick-arrow slick-prev" onClick={onClick} />
);

const NextArrow: React.FC<ArrowProps> = ({ onClick }) => (
  <div className="slick-arrow slick-next" onClick={onClick} />
);

const settings: Settings = {
  dots: false,
  arrows: true,
  slidesToShow: 10,
  slidesPerRow: 2,
  slidesToScroll: 5,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  infinite: false,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 8,
        slidesPerRow: 2,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 4,
        slidesPerRow: 3,
        slidesToScroll: 2,
      },
    },
  ],
};

const Head: React.FC<Pick<PickingBallProps, 'username'>> = ({ username }) => (
  <div className="t-pickingBall_head">
    <Container fullScreen>
      <div className="t-pickingBall_flex">
        <div className="t-pickingBall_title">
          <Image imgSrc={Title} ratio="508x52" alt="chon_so_du_thuong" size="cover" />
        </div>
        <div className="t-pickingBall_username">
          <p style={{ fontWeight: 700 }}>
            Xin chào quý khách
            <br />
            {' '}
            {username}
          </p>
        </div>
      </div>
    </Container>
  </div>
);

const Search: React.FC<Pick<PickingBallProps, 'handleChange'>> = ({ handleChange }) => (
  <div className="t-pickingBall_search">
    <div className="t-pickingBall_search-text">
      <Image imgSrc={SearchText} ratio="173x24" alt="nhap_so_can_tim" size="cover" />
    </div>
    <div className="t-pickingBall_search-input">
      <input type="number" id="search" name="search" onChange={handleChange} autoComplete="off" />
      <Icon iconName="search" iconSize="24x24" />
    </div>
  </div>
);

const Notice: React.FC = () => (
  <div className="t-pickingBall_notice">
    <div className="t-pickingBall_notice_wrap">
      <div className="t-pickingBall_notice-item">
        <div className="t-pickingBall_notice-ball">
          <Ball value="" variant="active" size={38} />
        </div>
        <Description idx={5}>
          Số quý khách đang chọn
        </Description>
      </div>
      <div className="t-pickingBall_notice-item">
        <div className="t-pickingBall_notice-ball">
          <Ball value="" variant="empty" size={38} />
        </div>
        <Description idx={5}>
          Số chưa được chọn
        </Description>
      </div>
      <div className="t-pickingBall_notice-item">
        <div className="t-pickingBall_notice-ball">
          <Ball value="" variant="disabled" size={38} />
        </div>
        <Description idx={5}>
          Số người khác đã chọn
        </Description>
      </div>
    </div>
  </div>
);

const Description: React.FC<DescriptionProps> = ({
  idx,
  giftCurrent,
  giftLimit,
  currentBall = 0,
  limitBall,
  children,
  handleTimeout,
}) => {
  switch (idx) {
    case 1:
      return (
        <p className="t-pickingBall_desc p1">
          Quý khách được chọn
          {' '}
          <Text type="span" modifiers={['jasmine', '700', '22x26']}>
            (
            {addZeroPrefixNumberPositive(limitBall)}
            )
          </Text>
          {' '}
          số dự thưởng
          {' '}
          <Text type="span" modifiers={['jasmine', '700', '22x26']}>
            (tương đương
            {' '}
            {addZeroPrefixNumberPositive(limitBall)}
            {' '}
            banh)
          </Text>
        </p>
      );

    case 2:
      return (
        <p className="t-pickingBall_desc p2">
          Di chuyển bảng số bên dưới theo mũi
          tên trái/ phải để tìm số và nhấn vào trái banh để
          {' '}
          <b>“CHỌN SỐ”</b>
        </p>
      );

    case 3:
      return (
        <p className="t-pickingBall_desc p3">
          Tổng số dự thưởng còn lại
          {' '}
          <b>
            {giftCurrent}
            /
            {giftLimit}
          </b>
        </p>
      );

    case 4:
      return (
        <p className="t-pickingBall_desc p4"><b>CHÚ THÍCH</b></p>
      );

    case 5:
      return (
        <p className="t-pickingBall_desc p5">
          {children}
        </p>
      );

    case 6:
      return (
        <p className="t-pickingBall_desc hide-br p6">
          QUÝ KHÁCH ĐÃ CHỌN
          {' '}
          {addZeroPrefixNumberPositive(currentBall)}
          /
          {addZeroPrefixNumberPositive(limitBall)}
          {' '}
          <br />
          {' '}
          SỐ DỰ THƯỞNG LẦN LƯỢT BÊN DƯỚI
        </p>
      );

    case 7:
      return (
        <p className="t-pickingBall_desc hide-br p7">
          Thời lượng chọn số dự thưởng
          {' '}
          <br />
          {' '}
          kết thúc trong
          {' '}
          <b>
            <Countdown
              timer={20}
              handleTimeout={handleTimeout}
            />
          </b>
        </p>
      );

    case 8:
      return (
        <p className="t-pickingBall_desc hide-br p8">
          Quý khách vui lòng chọn đủ
          {' '}
          <br />
          tất cả các số dự thưởng cần chọn
        </p>
      );

    default:
      return null;
  }
};

const ActiveBall: React.FC<Pick<PickingBallProps, 'activeBall' | 'handleShowAll'>> = ({
  activeBall,
  handleShowAll,
}) => {
  const { isMobile } = useIsMobile();
  const limit = useMemo(() => (isMobile ? 4 : 5), [isMobile]);
  const reorderBall = useMemo(() => {
    if (!activeBall) return [];
    if (activeBall?.length > limit) return activeBall.slice(Math.max(activeBall.length - limit, 1));
    return activeBall;
  }, [activeBall, limit]);
  return (
    <div className="t-pickingBall_current">
      {reorderBall?.slice(0, limit)?.map((e, i) => (
        <div className="t-pickingBall_current-item" key={`ball-active${i.toString()}`}>
          <Ball {...e} size={80} />
        </div>
      ))}
      {(activeBall && activeBall?.length > 4 && !isMobile)
        || (activeBall && activeBall?.length > 3 && isMobile) ? (
          <div className="t-pickingBall_current-item">
            <Ball handleClick={handleShowAll} variant="all" size={80} />
          </div>
        ) : null}
    </div>
  );
};

const Slider: React.FC<Pick<PickingBallProps, 'ball'|'searchValue' | 'handleClick' | 'resize'>> = ({
  ball, searchValue, resize, handleClick,
}) => {
  const dtBall = useMemo(() => {
    if (!searchValue) return ball;
    return ball.filter((f) => f.value.includes(searchValue));
  }, [searchValue, ball]);
  // const dtBall = useMemo(() => {
  //   if (searchValue && disabledFilter) {
  //     return ball.filter(
  //       (f) => f.variant !== 'disabled' && f.value.includes(searchValue),
  //     );
  //   }
  //   if (!searchValue && disabledFilter) {
  //     return ball.filter(
  //       (f) => f.variant !== 'disabled',
  //     );
  //   }
  //   if (searchValue && !disabledFilter) {
  //     return ball.filter(
  //       (f) => f.value.includes(searchValue),
  //     );
  //   }
  //   return ball;
  // }, [searchValue, disabledFilter, ball]);

  return (
    <>
      {ball.length === 1 ? (
        <div className="d-flex justify-content-center u-mt-18">
          <Ball
            value={ball[0].value}
            variant={ball[0].variant}
            size={resize ? 72 : 80}
            handleClick={() => handleClick && handleClick(ball[0])}
          />
        </div>
      ) : (
        <div className="t-pickingBall_list">
          <ReactSlick {...settings}>
            {dtBall?.map((e, i) => (
              <Ball
                {...e}
                size={resize ? 72 : 80}
                handleClick={() => handleClick && handleClick(e)}
                key={`i-${i.toString()}`}
              />
            ))}
          </ReactSlick>
        </div>
      )}
    </>
  );
};

const PickingBall: React.FC<PickingBallProps> = ({
  username,
  activeBall,
  ball,
  limitBall,
  isError,
  timer,
  searchValue,
  loadingPicking,
  keySlide,
  checkFilter,
  handleClick,
  handleChange,
  handleSubmit,
  handleShowAll,
  handleDuration,
  handleTimeout,
  handleCheck,
}) => {
  const [resize, setResize] = useState(false);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if (width && height && width >= 1200 && height < 765) {
      setResize(true);
    } else setResize(false);
  }, [width, height]);

  return (
    <div className={mapModifiers('t-pickingBall', resize && 'resize')}>
      <Head username={username} />
      <Container>
        {loadingPicking
            && (
            <div className="t-pickingBall_loading">
              <img src={loadingImg} alt="loading" />
              {/* <Description idx={3} giftCurrent={1200} giftLimit={1700} /> */}
            </div>
            )}
        <Description idx={1} limitBall={limitBall} />
        <Description idx={2} />
        <Search handleChange={handleChange} />
        <div className="t-pickingBall_filter">
          <Checkbox
            id="filter"
            checked={checkFilter}
            name="filter"
            modifiers="light"
            onChange={handleCheck}
          >
            <Text modifiers={['white']}>
              Lọc các số chưa chọn
            </Text>
          </Checkbox>
        </div>
        <div key={keySlide}>
          <Slider
            // disabledFilter={checked}
            searchValue={searchValue}
            resize={resize}
            ball={ball}
            handleClick={handleClick}
          />
        </div>
        <Notice />
        <Description idx={6} currentBall={activeBall?.length} limitBall={limitBall} />
        <ActiveBall activeBall={activeBall} handleShowAll={handleShowAll} />
        {
          timer
          && (
            <div
              className="t-numberPickingTemplate_review_desc u-mt-21"
            >
              <Text modifiers={['20x24', 'white', 'center', 'fontMyriad']}>
                Thời lượng chọn số dự thưởng kết thúc trong
                {' '}
                <Text type="span" modifiers={['20x24', 'white', 'fontMyriad', '700']}>
                  <Countdown
                    timer={timer}
                    handleTimeout={handleTimeout}
                    handleDuration={handleDuration}
                  />
                </Text>
              </Text>
            </div>
          )
        }
        {isError && (
          <Description idx={8} />
        )}
        <div className="t-pickingBall_submit">
          <Button handleClick={handleSubmit}>
            XÁC NHẬN CHỌN SỐ
          </Button>
        </div>
      </Container>
      <div className="o-footer_code">
        <Text modifiers={['10x14', '400', 'uppercase', 'center', 'white']}>
          COR-C-198-24
        </Text>
      </div>
    </div>
  );
};

export default PickingBall;
