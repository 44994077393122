import React, { useMemo } from 'react';

import DeviceOrientImage from 'assets/images/orient-device.png';
import Text from 'components/atoms/Text';
import useWindowDimensions from 'hooks/useWindowDimension';

interface DeviceWrappedProps {
  children?: React.ReactNode;
  requiredVertical?: boolean;
}

const DeviceWrapped = ({
  children, requiredVertical,
}: DeviceWrappedProps) => {
  const {
    isLandscape, isMobile, width,
  } = useWindowDimensions();
  const direction = requiredVertical ? isLandscape : !isLandscape;
  const showDeviceOriented = useMemo(() => direction
    && isMobile && width && width < 991,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [isLandscape, isMobile, width]);
  return (
    <>
      {
        showDeviceOriented
        && (
          <div className="t-devicewrapped">
            <img alt="mobile" src={DeviceOrientImage} />
            <Text modifiers={['white', 'center']}>
              Vui lòng xoay
              {' '}
              {requiredVertical ? 'dọc' : 'ngang'}
              {' '}
              màn hình điện thoại để trải nghiệm sự kiện một cách tốt nhất.
              <br />
              Xin Cảm ơn!
            </Text>
          </div>
        )
      }
      {children}
    </>
  );
};

DeviceWrapped.defaultProps = {
  children: <div />,
  requiredVertical: undefined,
};

export default DeviceWrapped;
