import React, { useEffect, useRef, useState } from 'react';

import contentsTopData, { contentsBottomData } from 'assets/dataDummy/guide';
import bannerMobileSrc from 'assets/images/banner-mobile.jpg';
// import bannerSrc from 'assets/images/customer.jpg';
import bannerSrc from 'assets/images/newbanner.jpg';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import HeadingSection from 'components/molecules/HeadingSection';
import Banner from 'components/organisms/Banner';
import Container from 'components/organisms/Container';
import Footer from 'components/organisms/Footer';
import Player from 'components/organisms/Player';
import DeviceWrappedVertical from 'components/templates/DeviceWrappedVertical';
import Guide from 'components/templates/Guide';
import NewsEventsList from 'components/templates/NewsEventsList';
import RegisterForm from 'components/templates/RegisterForm';
import ShowContent from 'components/templates/ShowContent';
import eventConfigs from 'configs';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import useWindowScroll from 'hooks/useWindowScroll';
import useWindowSize from 'hooks/useWindowSize';
import { getDimensions, scrollDownNextSection } from 'utils/functions';

const headerData = ['THỜI GIAN', 'NỘI DUNG'];
const rowData = [
  {
    time: '14:00 - 16:30',
    content: 'Truyền hình trực tiếp bỏ banh vào lồng cầu',
  },
  {
    time: '16:30 - 17:30',
    content: 'Đón khách',
  },
  {
    time: '17:30 - 17:40',
    content: 'Khai mạc hội nghị',
  },
  {
    time: '17:40 - 17:45',
    content: 'Phát biểu của đại diện Abbott',
  },
  {
    time: '17:45 - 17:55',
    content: 'Vinh danh Nhà Phân Phối năm 2024',
  },
  {
    time: '17:55 - 18:05',
    content: 'Vinh danh khách hàng đạt doanh số cao nhất năm 2024',
  },
  {
    time: '18:05 - 20:00',
    content: 'Quay số trúng thưởng và tiệc tối',
  },
  {
    time: '20:00',
    content: 'Kết thúc hội nghị và tặng quà ra về',
  },
];
const Home: React.FC = () => {
  const { width, height } = useWindowSize();
  const [muted, setMuted] = useState(true);
  const [isScroll, setIsScroll] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const bannerRef = useRef<HTMLDivElement>(null);
  const eventsRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const registerRef = useRef<HTMLDivElement>(null);
  const agendaRef = useRef<HTMLDivElement>(null);
  const guideRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const isMountedRef = useRef(true);
  const [visibleSection, setVisibleSection] = useState('');
  const [isFull, setIsFull] = useState(false);

  const sectionRefs = [
    { section: 'DANH SÁCH SỰ KIỆN', ref: eventsRef },
    { section: 'THÔNG TIN CHƯƠNG TRÌNH', ref: infoRef },
    { section: 'đăng ký tham gia', ref: registerRef },
    { section: 'nội dung hội nghị', ref: agendaRef },
    { section: 'hướng dẫn & liên hệ', ref: guideRef },
  ];

  useMutedAutoplay(videoRef, setMuted);

  // scroll to Active section
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 72;

      const selected = sectionRefs.find(({ ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition + 72 > offsetTop && scrollPosition + 72 < offsetBottom;
        }
        return false;
      });

      if (selected && selected.section !== visibleSection && isMountedRef.current) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection && isMountedRef.current) {
        setVisibleSection('');
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      isMountedRef.current = false;
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onScrollPlayer
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight / 2 && videoRef.current) {
        videoRef.current.play();
        window.removeEventListener('scroll', handleScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on scroll fix position menu
  useWindowScroll(() => {
    if (bannerRef.current && window.pageYOffset > bannerRef.current?.clientHeight) {
      setIsScroll(true);
      return;
    }
    setIsScroll(false);
  });

  useEffect(() => {
    if (isFull) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }
  }, [isFull]);

  return (
    <DeviceWrappedVertical requiredVertical>
      <div className="p-home">
        <section id="banner" ref={bannerRef}>
          <Banner
            imageDesktop={bannerSrc}
            imageMobile={bannerMobileSrc}
            imageTablet={bannerSrc}
            title="ĐĂNG KÝ"
            handleClick={() => scrollDownNextSection(registerRef, menuRef.current?.clientHeight)}
            handleClickInfo={() => scrollDownNextSection(infoRef, menuRef.current?.clientHeight)}
          />
        </section>
        <div
          ref={menuRef}
          className={`t-newsEventsList_menu${isScroll ? ' fixed' : ''}`}
        >
          <div className="t-newsEventsList_menu_wrap">
            {
                sectionRefs.map((ele, idx) => (
                  <Button
                    key={`i-${idx.toString()}`}
                    color="pigment"
                    variant="secondary"
                    size="sm"
                    className={visibleSection === ele.section ? 'active' : ''}
                    handleClick={() => scrollDownNextSection(
                      ele.ref, menuRef.current?.clientHeight,
                    )}
                  >
                    {ele.section}
                  </Button>
                ))
              }
          </div>
        </div>
        <section id="newsEvents" ref={eventsRef} className={isScroll ? ' marginTop' : ''}>
          <NewsEventsList />
        </section>
        <Container className="o-container_home" noPaddingLeft noPaddingRight>
          <section id="showInfo" style={{ zIndex: isFull ? 999 : 'unset' }} ref={infoRef}>
            <div className="t-showInfo">
              <Container>
                <div className="t-showInfo_inner">
                  <div className="t-showInfo_title">
                    <HeadingSection>THÔNG TIN CHƯƠNG TRÌNH</HeadingSection>
                  </div>
                  <div
                    className="t-showInfo_wrapper"
                    style={isFull ? {
                      width: `${width}px`, height: `${height}px`, position: 'fixed', top: 0, left: 0, zIndex: 2, background: '#000', padding: 0,
                    } : undefined}
                  >
                    <Player
                      isMuted={muted}
                      src={eventConfigs.landingpageVideo}
                      ref={videoRef}
                      isLoop
                    />
                    <div className="t-showInfo_mute" onClick={() => setMuted(!muted)}>
                      <Icon iconName={muted ? 'mute' : 'sound'} iconSize="32x32" />
                    </div>
                    <div className="t-showInfo_fullscreen" onClick={() => setIsFull(!isFull)}>
                      <Icon iconName={isFull ? 'expand' : 'minimize'} iconSize="32x32" />
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </section>
          <section id="register" ref={registerRef}>
            <Container>
              <RegisterForm />
            </Container>
            {/* <div className="p-home_texture">
            <img src={sectionTextureImg} alt="texture" />
          </div> */}
          </section>
          <section id="ShowContent" ref={agendaRef}>
            <ShowContent
              title="nội dung hội nghị"
              headerData={headerData}
              rowData={rowData}
            />
          </section>
          <section id="guide" ref={guideRef}>
            <Guide
              contentsTop={contentsTopData}
              contentsBottom={contentsBottomData}
            />
          </section>
        </Container>
        <Footer />
      </div>
    </DeviceWrappedVertical>
  );
};

export default Home;
