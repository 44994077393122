import React from 'react';

import DeviceWrappedChildren from 'components/templates/DeviceWrappedChildren';
import IntroductionTemplate from 'components/templates/IntroductionTemplate';

const Introduction: React.FC = () => (
  <DeviceWrappedChildren>
    <div className="p-introduction">
      <IntroductionTemplate />
    </div>
  </DeviceWrappedChildren>
);

export default Introduction;
