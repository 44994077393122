import React from 'react';

import WaitingTemplate from 'components/templates/Waiting';
import { useAppSelector } from 'store/hooks';

const Waiting: React.FC = () => {
  const { data: profileData } = useAppSelector((state) => state.me);

  return (
    <div className="p-waiting">
      <WaitingTemplate
        account={profileData?.account}
        timeStart={profileData?.event_time.display_number_selection_time}
        timeEnd={profileData?.event_time.display_end_number_selection_time}
        address={profileData?.event_time.name}
      />
    </div>
  );
};

export default Waiting;
