import React, { useEffect, useRef, useState } from 'react';

import icMute from 'assets/icons/ic_mute_blue.png';
import icUnMute from 'assets/icons/ic_unmute_blue.png';
import background from 'assets/images/bg-popup-video.png';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Player from 'components/organisms/Player';
import SimiPopup from 'components/organisms/SimiPopup';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import useWindowDimensions from 'hooks/useWindowDimension';
import { BootLayerMediaTypes } from 'services/configs/type';

interface PlayerPopupProps {
  isOpen: boolean;
  mediaSrc: string;
  type?: BootLayerMediaTypes;
  modifier?: string;
  handleNext?: () => void;
}

const PlayerPopup: React.FC<PlayerPopupProps> = ({
  isOpen, mediaSrc, modifier, type = 'video', handleNext,
}) => {
  const videoPopupRef = useRef<HTMLVideoElement>(null);
  const { isIOS: iosDetect, width: winWidth } = useWindowDimensions();
  const [openPopup, setOpenPopup] = useState(false);

  // States
  const [isMuted, setIsMuted] = useState(false);
  useMutedAutoplay(videoPopupRef, setIsMuted);

  useEffect(() => {
    if (type !== 'iframe') return;
    let gameStatus = '';
    setInterval(() => {
      gameStatus = localStorage.getItem('endGame') || '';
      if (gameStatus === 'done' && handleNext) {
        handleNext();
        localStorage.removeItem('endGame');
      }
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const renderContent = () => {
    switch (type) {
      case 'image':
        return (
          <div className="t-playerPopup_image">
            <img src={mediaSrc} alt="alt" />
          </div>
        );
      case 'iframe':
        return (
          <div className="t-playerPopup_iframe">
            <iframe src={mediaSrc} title="game" width="100%" height="100%" />
          </div>
        );

      default:
        return (
          <div className="t-playerPopup_video">
            <Image imgSrc={background} alt="background" size="cover" ratio="popupVideo" />
            <div className="t-playerPopup_content">
              <Player
                ref={videoPopupRef}
                src={mediaSrc}
                isMuted={isMuted}
                autoplay
              />
              <div className="t-playerPopup_controls">
                <div
                  className={`t-playerPopup_speaker${isMuted ? ' muted' : ''}`}
                  onClick={() => setIsMuted(!isMuted)}
                >
                  <div className="wrap">
                    <img
                      src={isMuted ? icMute : icUnMute}
                      alt="mute"
                    />
                    <p>BẬT ÂM THANH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };
  return (
    <div className="t-playerPopup">
      <SimiPopup
        isOpenPopup={isOpen}
        hasCloseBtn={type !== 'video'}
        handleButtonClose={handleNext}
        modifiers={`player ${type}`}
      >
        {renderContent()}
        {
          type === 'image' && modifier === 'download'
          && (
            <>
              <div className="t-playerPopup_download">
                {/* <Link to="/files/glucerna.pdf" target="_blank" download>Tải về</Link> */}
                {
                  winWidth && winWidth < 1199 && iosDetect ? (
                    <a
                      href="/files/glucerna.pdf"
                      download
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenPopup(true);
                        setTimeout(() => {
                          setOpenPopup(false);
                        }, 5000);
                      }}
                    >
                      Tải về
                    </a>
                  )
                    : <Link href="/files/glucerna.pdf" download useExternal>Tải về</Link>
                }
              </div>
              {
                openPopup
                && (
                  <div className="tag-save">
                    <Text modifiers={['pigment', '14x19', 'center']}>
                      BẤM GIỮ NÚT ĐỂ TẢI VỀ
                    </Text>
                  </div>
                )
              }
            </>
          )
        }
      </SimiPopup>
      <>
        {
          isOpen && type === 'video'
          && (
            <>
              <div className="t-playerPopup_review">
                <Button
                  variant="warning"
                  size="sm"
                  handleClick={() => {
                    if (videoPopupRef.current) {
                      videoPopupRef.current.currentTime = 0;
                      videoPopupRef.current.play();
                      // setIsEnded(false);
                    }
                  }}
                >
                  Xem Lại
                </Button>
              </div>
              <div className="t-playerPopup_next">
                <Button
                  size="sm"
                  variant="warning"
                  handleClick={() => {
                    // setIsEnded(false);
                    setIsMuted(false);
                    if (handleNext) {
                      handleNext();
                    }
                  }}
                >
                  Đi tiếp
                </Button>
              </div>
            </>
          )
        }
      </>
    </div>
  );
};

PlayerPopup.defaultProps = {
  handleNext: undefined,
  type: undefined,
  modifier: undefined,
};

export default PlayerPopup;
