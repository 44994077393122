/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AuthTypes } from './type';

import { initialTokenSession } from 'services/common/storage';
import { loginMemberService } from 'services/member';
import { LoginMemberParams, LoginMemberResponse } from 'services/member/types';

interface AuthState {
  data?: AuthTypes; // Change later
  loading: boolean;
  error?: ErrorResponse;
}

const initialState: AuthState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export const loginAction = createAsyncThunk<
  LoginMemberResponse,
  LoginMemberParams,
  { rejectValue: ErrorResponse }
>('LOGIN_ACTIVE', async (params: LoginMemberParams, { rejectWithValue }) => {
  try {
    const res = await loginMemberService(params);
    if (res.token) {
      initialTokenSession({ accessToken: res.token });
    }
    return res;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(loginAction.pending, ($state) => {
      $state.error = undefined;
    });
    builder.addCase(loginAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
    });
    builder.addCase(loginAction.rejected, ($state, action) => {
      $state.error = action.payload;
    });
  },
});

export default authSlice.reducer;
