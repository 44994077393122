import React from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Popup from 'components/organisms/Popup';

interface PopupTimeoutProps {
  isShowPopup: boolean;
  handleClose?: () => void;
}

const PopupTimeout: React.FC<PopupTimeoutProps> = ({
  isShowPopup,
  handleClose,
}) => (
  <Popup
    modifiers="timeout"
    isOpenModal={isShowPopup}
    title="ĐÃ HẾT THỜI GIAN CHỌN SỐ"
    handleClose={handleClose}
    hasCloseButton
  >
    <Text modifiers={['16x22', 'jet', 'center']}>
      Quý khách vui lòng chọn lại
      số dự thưởng
    </Text>
    <div className="submit">
      <Button handleClick={handleClose}>
        BẮT ĐẦU LẠI
      </Button>
    </div>
  </Popup>
);

PopupTimeout.defaultProps = {
  handleClose: undefined,
};

export default PopupTimeout;
