// Hook

import { useEffect, useState } from 'react';

// T is a generic type for value parameter, our case this will be string
function useDebouceInput<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay],
  );
  return debouncedValue;
}

export default useDebouceInput;
