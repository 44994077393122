/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';

export interface SelfieProcessProps {
  takeSelfie: () => string;
}

interface ProcessProps {
  borderImg: string;
  handlePermission?: (val: PermissionState)=> void;
}

const Processing = forwardRef<SelfieProcessProps, ProcessProps>((
  { borderImg, handlePermission }, ref,
) => {
  const videoRef = useRef<null | HTMLVideoElement>(null);

  useEffect(() => {
    let terminateWorker: () => void;
    let stopCamera: () => void;

    (async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: 'user',
            aspectRatio: 4 / 3,
          },
        });
        stopCamera = () => {
          mediaStream.getTracks().forEach((t) => t.stop());
        };
        if (!videoRef.current) {
          console.log('red');
          return;
        }
        videoRef.current.srcObject = mediaStream;
        if (handlePermission) handlePermission('granted');
      } catch (error) {
        if (handlePermission) handlePermission('denied');
      }
    })();

    return () => {
      if (terminateWorker) {
        terminateWorker();
      }
      if (stopCamera) {
        stopCamera();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    takeSelfie: (): string => {
      const cameraEl = videoRef.current;
      if (!cameraEl) {
        return '';
      }

      const canvas = document.createElement('canvas');
      const cameraWidth = cameraEl.videoWidth;
      const cameraHeight = cameraEl.videoHeight;
      const ratio = cameraEl.clientWidth / cameraEl.clientHeight;

      const canvasWidth = Math.min(cameraWidth, cameraHeight * ratio);
      const canvasHeight = Math.min(cameraHeight, cameraWidth / ratio);

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const ctx = canvas.getContext('2d');

      ctx?.drawImage(cameraEl,
        0.5 * (canvasWidth - cameraWidth), 0.5 * (canvasHeight - cameraHeight));
      const img = canvas.toDataURL('image/jpeg');

      return img;
    },
  }));

  return (
    <div className="t-selfie_frame_video frame">
      <div
        className="t-selfie_frame_video_border"
      >
        <img src={borderImg} alt="frame" crossOrigin="anonymous" />
      </div>
      <div className="t-selfie_frame_video_main">
        <video
          autoPlay
          playsInline
          muted
          ref={videoRef}
        />
      </div>
    </div>
  );
});

Processing.defaultProps = {
  handlePermission: undefined,
};

export default Processing;
