import React from 'react';

import HeadingSection from '../HeadingSection';

interface SectionTitleProps {
  title?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  children,
}) => (
  <div className="m-sectionTitle">
    {title && (
    <div className="m-sectionTitle_title">
      <HeadingSection>{title}</HeadingSection>
    </div>
    )}
    {children}
  </div>
);

SectionTitle.defaultProps = {
};

export default SectionTitle;
