import React from 'react';

import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import HeadingSection from 'components/molecules/HeadingSection';
import NumberBall from 'components/molecules/NumberBall';
import NumberBannerLayout from 'components/organisms/NumberBannerLayout';
import { addZeroPrefixNumberPositive } from 'utils/functions';

interface NumberCongrateProps {
  account?: string;
  time?: string;
  address?: string;
  numberPickedList?: string[];
  numberAvailable?: number;
}
interface NumberCongrateInfoProps {
  label?: string;
  value?: string | number;
}

const NumberCongrateInfo: React.FC<NumberCongrateInfoProps> = ({
  label, value,
}) => (
  <div className="t-numberCongrate_info_item">
    <Text modifiers={['400', 'jet', 'fontUrmAvo', '16x22']}>
      {label}
      {' '}
      <Text type="span" modifiers={['700', 'pigment', 'fontUrmAvo', '16x22']}>
        {value}
      </Text>
    </Text>
  </div>
);

const NumberCongrate: React.FC<NumberCongrateProps> = ({
  account,
  time,
  address, numberPickedList, numberAvailable,
}) => (
  <div className="t-numberCongrate">
    <NumberBannerLayout>
      <div className="t-numberCongrate_panel">
        <div className="t-numberCongrate_panel_wrap">
          <div className="t-numberCongrate_heading">
            <HeadingSection modifiers="secondary">
              CHÚC MỪNG QUÝ KHÁCH ĐÃ CHỌN SỐ THÀNH CÔNG
            </HeadingSection>
          </div>
          <div className="t-numberCongrate_content">
            <Text modifiers={['700', 'jet', 'fontUrmAvo', '16x22']}>
              Thông tin tham dự chương trình
            </Text>
            <div className="t-numberCongrate_info">
              {/* Account  */}
              <NumberCongrateInfo
                label="Tài khoản đăng nhập:"
                value={account}
              />
              {/* Time  */}
              <NumberCongrateInfo
                label="Thời gian bắt đầu chương trình quay hình trực tuyến bỏ banh vào lồng cầu:"
                value={time}
              />
              {/* Address  */}
              <NumberCongrateInfo
                label="Địa điểm tổ chức quay số:"
                value={address}
              />
              {/* Number  */}
              <NumberCongrateInfo
                label="Tổng số lượng số dự thưởng:"
                value={addZeroPrefixNumberPositive(numberAvailable)}
              />
            </div>
            <div className="t-numberCongrate_info_item">
              <Text modifiers={['400', 'jet', 'fontUrmAvo', '16x22']}>
                Số dự thưởng đã chọn:
              </Text>
              {
                  numberPickedList
                  && numberPickedList.length > 0
                  && (
                  <div className="t-numberCongrate_list">
                    {
                      numberPickedList.map((val, idx) => (
                        <div
                          className="t-numberCongrate_list_item"
                          key={`i-${idx.toString()}`}
                        >
                          <NumberBall
                            value={val}
                            type="picking"
                            disabled
                          />
                        </div>
                      ))
                    }
                  </div>
                  )
                  // && (
                  //   <div className="t-numberCongrate_slider">
                  //     <div className="t-numberCongrate_slider_wrap">
                  //       <Carousel settings={settingDefault}>
                  //         {
                  //           numberPickedList.length > 0
                  //           && numberPickedList.map((val, idx) => (
                  //             <div
                  //               className="t-numberCongrate_slider_item"
                  //               key={idx.toString()}
                  //             >
                  //               <NumberBall
                  //                 value={val}
                  //                 type="picking"
                  //                 disabled
                  //               />
                  //             </div>
                  //           ))
                  //         }
                  //       </Carousel>
                  //     </div>
                  //   </div>
                  // )
                }
            </div>
            <div className="u-mt-24">
              <Text modifiers={['400', 'jet', 'fontUrmAvo', '16x22']}>
                Mọi thắc mắc xin vui lòng liên hệ tổng đài:
                {' '}
                <Link href="tel:0918454049" useExternal>
                  <Text type="span" modifiers={['700', 'jet', 'fontUrmAvo', '16x22']}>
                    0918 45 40 49
                  </Text>
                </Link>
              </Text>
            </div>
            <div className="u-mt-md-32 u-mt-18">
              <Text modifiers={['fontUrmAvo', '20x24', 'pigment', 'center', 'uppercase', '700', 'italic']}>
                chúc quý khách may mắn
              </Text>
            </div>
          </div>
        </div>
      </div>
    </NumberBannerLayout>
  </div>
);

NumberCongrate.defaultProps = {
  account: undefined,
  time: undefined,
  address: undefined,
  numberPickedList: undefined,
  numberAvailable: undefined,
};
NumberCongrateInfo.defaultProps = {
  label: undefined,
  value: undefined,
};

export default NumberCongrate;
