import {
  GetMemberParams,
  GetMemberResponse,
  LoginMemberParams,
  MemberDataTypes,
  MemberInfoData,
} from './types';

import axiosInstance from 'services/common/instance';
import { AuthTypes } from 'store/type';

export const loginMemberService = async (
  params: LoginMemberParams,
): Promise<AuthTypes> => {
  const res = await axiosInstance.post('members/login', params);
  return res.data.data;
};

export const getProfileService = async (): Promise<MemberDataTypes> => {
  const res = await axiosInstance.get('members/me');
  return res.data;
};

export const getMemberService = async (params: GetMemberParams): Promise<GetMemberResponse> => {
  const res = await axiosInstance.get(`members/${params}`);
  return res.data.data;
};

export const forgotPasswordService = async (
  params: GetMemberParams,
): Promise<GetMemberResponse> => {
  const res = await axiosInstance.post(`members/${params}/forgot-password`);
  return res.data.data;
};

export const getInfoMemberService = async (params: { code: string }): Promise<MemberInfoData> => {
  const res = await axiosInstance.post(`members/get-info/${params.code}`);
  return res.data.data;
};

export const getWSTokenService = async (): Promise<string> => {
  const res = await axiosInstance.post('members/ws-token');
  return res.data.data?.wsToken;
};
