import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { getTokenSession } from './storage';

const API_URL_PREFIX = '/api/v1/';

const axiosInstance = axios.create({
  baseURL: API_URL_PREFIX,
});

axiosInstance.interceptors.request.use(
  ($config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = getTokenSession();

    if (token) {
      $config.headers.Authorization = `Bearer ${token.accessToken}`;
    }
    $config.headers['Content-Type'] = 'application/json';
    $config.headers.Accept = 'application/json';
    return $config;
  },
  async (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  async (error: AxiosError): Promise<AxiosError> => Promise.reject(
    error.response ? error.response.data.errors : error,
  ),
);
export default axiosInstance;
