import img01Src from 'assets/images/events/event-01.jpg';
import img02Src from 'assets/images/events/event-02.jpg';
import img03Src from 'assets/images/events/event-03.jpg';
import img04Src from 'assets/images/events/event-04.jpg';
import instruction1MobileImg from 'assets/images/instruction/instruction1-mobile.jpg';
import instruction1Img from 'assets/images/instruction/instruction1.jpg';
import instruction2MobileImg from 'assets/images/instruction/instruction2-mobile.jpg';
import instruction2Img from 'assets/images/instruction/instruction2.jpg';
import instruction3MobileImg from 'assets/images/instruction/instruction3-mobile.jpg';
import instruction3Img from 'assets/images/instruction/instruction3.jpg';
import instruction4MobileImg from 'assets/images/instruction/instruction4-mobile.jpg';
import instruction4Img from 'assets/images/instruction/instruction4.jpg';
import instruction5MobileImg from 'assets/images/instruction/instruction5-mobile.jpg';
import instruction5Img from 'assets/images/instruction/instruction5.jpg';
import { IconName } from 'components/atoms/Icon';
import { NumberListTypes } from 'components/templates/NumberPickingTemplate';

const instructionDataImage = [
  {
    step: 1,
    pc: instruction1Img,
    mobile: instruction1MobileImg,
  },
  {
    step: 2,
    pc: instruction2Img,
    mobile: instruction2MobileImg,
  },
  {
    step: 3,
    pc: instruction3Img,
    mobile: instruction3MobileImg,
  },
  {
    step: 4,
    pc: instruction4Img,
    mobile: instruction4MobileImg,
  },
  {
    step: 5,
    pc: instruction5Img,
    mobile: instruction5MobileImg,
  },
];

export const ballListDummy: NumberListTypes[] = [
  {
    isChosen: false,
    value: '401',
    checked: false,
  },
  {
    isChosen: false,
    value: '402',
    checked: false,
  },
  {
    isChosen: true,
    value: '403',
    checked: false,
  },
];

export const newsEventList = [
  {
    img: img04Src,
    subTitle: 'Khu vực',
    title: 'MIỀN TRUNG',
    cardNews: {
      date: '09/01/2025',
      informations: [
        {
          icon: 'location' as IconName,
          content: `Địa điểm:<br/>
          <span style="font-weight:700"> Sheraton Grand Danang Resort & Convention Center </span><br/>
          35 Trường Sa, Phường Hoà Hải,<br /> Quận Ngũ Hành Sơn, TP. Đà Nẵng`,
        },
        {
          icon: 'time' as IconName,
          content: 'Thời gian: 14h - 20h',
        },
      ],
    },
    cardNewsMobile: {
      date: '09/01/2025',
      informations: [
        {
          icon: 'location' as IconName,
          content: `Địa điểm:<br/>
          <span style="font-weight:700"> Sheraton Grand Danang Resort & Convention Center </span><br/>
          35 Trường Sa, Phường Hoà Hải,<br /> Quận Ngũ Hành Sơn, TP. Đà Nẵng`,
        },
        {
          icon: 'time' as IconName,
          content: 'Thời gian: 14h - 20h',
        },
      ],
    },
  },
  {
    img: img01Src,
    subTitle: 'Khu vực',
    title: 'ĐỒNG BẰNG SÔNG CỬU LONG',
    cardNews: {
      date: '11/01/2025',
      informations: [
        {
          icon: 'location' as IconName,
          content: `Địa điểm:<br/>
            <span style="font-weight:700"> Sheraton Cần Thơ  </span><br/>
              209 đường 30 tháng 4, Phường Xuân Khánh,<br/>Quận Ninh Kiều, Cần Thơ`,
        },
        {
          icon: 'time' as IconName,
          content: 'Thời gian: 14h - 20h',
        },
      ],
    },
    cardNewsMobile: {
      date: '11/01/2025',
      informations: [
        {
          icon: 'location' as IconName,
          content: `Địa điểm:<br/>
            <span style="font-weight:700"> Sheraton Cần Thơ  </span><br/>
              209 đường 30 tháng 4, Phường
            Xuân Khánh, Quận Ninh Kiều, Cần Thơ`,
        },
        {
          icon: 'time' as IconName,
          content: 'Thời gian: 14h - 20h',
        },
      ],
    },
  },
  {
    img: img03Src,
    subTitle: 'Khu vực',
    title: 'MIỀN BẮC',
    cardNews: {
      date: '13 - 14/01/2025',
      informations: [
        {
          icon: 'location' as IconName,
          content: `Địa điểm:<br/>
          <span style="font-weight:700">Khách sạn Lotte Hà Nội</span><br/>
          Số 54, Đường Liễu Giai, Phường Cống Vị,<br/>
          Quận Ba Đình, Hà Nội`,
        },
        {
          icon: 'time' as IconName,
          content: 'Thời gian: 14h - 20h',
        },
      ],
    },
    cardNewsMobile: {
      date: '13 - 14/01/2025',
      informations: [
        {
          icon: 'location' as IconName,
          content: `Địa điểm:<br/>
          <span style="font-weight:700">Khách sạn Lotte Hà Nội</span><br/>
          Số 54, Đường Liễu Giai, Phường Cống Vị, Quận Ba Đình, Hà Nội`,
        },
        {
          icon: 'time' as IconName,
          content: 'Thời gian: 14h - 20h',
        },
      ],
    },
  },
  {
    img: img02Src,
    subTitle: 'Khu vực',
    title: 'HỒ CHÍ MINH',
    cardNews: {
      date: '16 - 17 - 18/01/2025',
      informations: [
        {
          icon: 'location' as IconName,
          content: `Địa điểm:<br/>
          <span style="font-weight:700"> Trung tâm hội nghị GEM Center </span><br/>
          8 Nguyễn Bỉnh Khiêm, Phường Đa Kao, Quận 1,<br /> TP. HCM`,
        },
        {
          icon: 'time' as IconName,
          content: 'Thời gian: 14h - 20h',
        },
      ],
    },
    cardNewsMobile: {
      date: '16 - 17 - 18/01/2025',
      informations: [
        {
          icon: 'location' as IconName,
          content: `Địa điểm:<br/>
          <span style="font-weight:700"> Trung tâm hội nghị GEM Center </span><br/>
          8 Nguyễn Bỉnh Khiêm, Phường Đa Kao, Quận 1, TP. HCM`,
        },
        {
          icon: 'time' as IconName,
          content: 'Thời gian: 14h - 20h',
        },
      ],
    },
  },
];

export default instructionDataImage;
