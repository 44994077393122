import { GeneralTypes } from './type';

import axiosInstance from 'services/common/instance';

const getGeneralService = async (): Promise<GeneralTypes> => {
  const res = await axiosInstance.get('systems/general');
  return res.data.data;
};

export default getGeneralService;
