/* eslint-disable */
import { useEffect, useRef } from "react";

import { getWSTokenService } from "services/member";
import { addNewEmojiSocket, addNewLuckyDrawsSocket, addNewNumberSocket, addResetNumberSocket, setStatusSocket } from "store/general";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getMeAction } from "store/me";
import socket from "utils/socket";

const SocketPush: React.FC = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.me.data);
  const init = useRef(true);

  const connectWebSocket = () => {
    try {
      const { protocol, host } = window.location;
      const url = `${protocol === 'https:' ? 'wss' : 'ws'}://${host}/ws`;
      // local
      // const url = `wss://staging.hoinghikhachhangclp.com/ws`;
      socket(url, getWSTokenService, {
        onStateChange(status) {
          dispatch(setStatusSocket(status));
        },
        onMessage(data) {
          const parsedData = JSON.parse(data);
          switch (parsedData.event) {
            case 'updated':
              dispatch(getMeAction());
              break;
            case 'LuckyNumberResult':
              dispatch(addNewLuckyDrawsSocket(parsedData.data));
              break;
            case 'emoji':
              dispatch(addNewEmojiSocket(parsedData.data));
              break;
            case 'NumberSelection':
              dispatch(addNewNumberSocket({ number: parsedData.data, userData: profile }));
              break;
            case 'ResetNumberSelection':
              dispatch(addResetNumberSocket(parsedData.data));
              break;
            default:
              break;
          }
        },
      });
    } catch (error) {
      // Throw error when the WebSocket connection fails
    }
  };

  useEffect(() => {
    if (profile && init.current) {
      init.current = false;
      connectWebSocket();
    }
  }, [profile]);

  return null;
};

export default SocketPush;
