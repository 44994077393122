import React from 'react';

import PickingBallWrapper from 'containers/PickingBall';

const Picking: React.FC = () => (
  <div className="p-picking">
    <PickingBallWrapper />
  </div>
);

export default Picking;
