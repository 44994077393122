/* eslint-disable no-unused-vars */
import React from 'react';

import { BallType } from '../PickingBall';

import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import { TimeTypes } from 'components/molecules/Countdown';
import NumberBall from 'components/molecules/NumberBall';
import Popup from 'components/organisms/Popup';

interface ConfirmNumberPopupProps {
  openPopup: boolean;
  numberPickedList?: string[];
  activeBallList?: BallType[];
  name?: string;
  limitNumber?: number;
  timer?: TimeTypes;
  handleClose?: () => void;
  handleSubmit?: () => void;
}

const ConfirmNumberPopup: React.FC<ConfirmNumberPopupProps> = ({
  openPopup,
  numberPickedList,
  activeBallList,
  limitNumber,
  name,
  timer,
  handleClose,
  handleSubmit,
}) => (
  <div className="t-confirmNumberPopup">
    <Popup
      modifiers="confirmNumber"
      isOpenModal={openPopup}
      title="KẾT QUẢ CHỌN SỐ"
      handleClose={handleClose}
    >
      <Text modifiers={['jet', '400', '16x22', 'fontUrmAvo']}>
        Xin cảm ơn quý khách
        {' '}
        <Text type="span" modifiers={['jet', '700', '16x22', 'fontUrmAvo']}>
          {name}
        </Text>
        {' '}
        đã tham gia chọn số dự thưởng
      </Text>
      <div className="u-mt-8">
        <Text modifiers={['jet', '400', '16x22', 'fontUrmAvo']}>
          Quý khách đã chọn đủ
          {' '}
          <Text type="span" modifiers={['pigment', '700', '16x22', 'fontUrmAvo']}>
            (
            {activeBallList
              && activeBallList?.length >= 10 ? activeBallList?.length : `0${activeBallList?.length}`}
            /
            {limitNumber && (limitNumber >= 10 ? limitNumber : `0${limitNumber}`)}
            )
            {' '}
            số dự thưởng.
          </Text>
        </Text>
      </div>
      <div className="u-mt-8">
        <Text modifiers={['jet', '400', '16x22', 'fontUrmAvo']}>
          Các số của quý khách đã chọn là
        </Text>
      </div>
      <div className="t-confirmNumberPopup_slider">
        {
          activeBallList && activeBallList.length > 0
          && activeBallList.map((val, idx) => (
            <div
              className="t-confirmNumberPopup_slider_item"
              key={`i-${idx.toString()}`}
            >
              <NumberBall
                value={val.value}
                type="picking"
                size="sm"
                disabled
              />
            </div>
          ))
        }
      </div>
      {
        timer
        && (
          <div className="t-confirmNumberPopup_slider_desc">
            <Text modifiers={['pigment', '400', '16x22', 'center', 'fontUrmAvo']}>
              Thời lượng chọn số dự thưởng sẽ kết thúc trong
              {' '}
              <Text type="span" modifiers={['pigment', '700', '16x22', 'fontUrmAvo']}>
                <span className="m-countdown">
                  {' '}
                  {timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes}
                  {' '}
                  phút
                  {' '}
                  {timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}
                  {' '}
                  giây
                </span>
              </Text>
            </Text>
          </div>
        )
      }
      <div className="u-mt-10">
        <Text modifiers={['jet', '400', '16x22', 'fontUrmAvo']}>
          Quý khách vui lòng kiểm tra tất cả số dự thưởng đã chọn và bấm nút
          {' '}
          <Text type="span" modifiers={['pigment', '700', '16x22', 'uppercase', 'fontUrmAvo']}>
            xác nhận
          </Text>
          {' '}
          bên dưới để hoàn tất chọn số.
        </Text>
      </div>
      <div className="u-mt-10">
        <Text modifiers={['jet', '400', '16x22']}>
          Mọi thắc mắc xin vui lòng liên hệ tổng đài:
          {' '}
          <Link href="tel:0918454049" useExternal>
            <Text type="span" modifiers={['jet', '700', '16x22', 'uppercase', 'fontUrmAvo']}>
              0918 45 40 49
            </Text>
          </Link>
        </Text>
      </div>
      <div className="u-mt-10 u-mt-md-20 u-mb-10 u-mb-md-20">
        <Text modifiers={['pigment', '20x24', 'uppercase', '700', 'center', 'fontUrmAvo']}>
          chúc quý khách may mắn
        </Text>
      </div>
      <div className="o-popup_button mobile-reverse">
        <Button
          size="sm"
          handleClick={handleClose}
        >
          QUAY LẠI
        </Button>
        <Button
          size="sm"
          handleClick={handleSubmit}
        >
          XÁC NHẬN
        </Button>
      </div>
    </Popup>
  </div>
);

ConfirmNumberPopup.defaultProps = {
  handleClose: undefined,
  name: undefined,
  numberPickedList: undefined,
  limitNumber: undefined,
  handleSubmit: undefined,
  activeBallList: undefined,
  timer: undefined,
};

export default ConfirmNumberPopup;
