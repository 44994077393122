import React, { useEffect } from 'react';

import NumberWelcomeTemplate from 'components/templates/NumberWelcome';
import SLUG_PARAMS from 'utils/constants';

const NumberWelcome: React.FC = () => {
  useEffect(() => {
    document.body.classList.add('body-introduction');
  }, []);
  return (
    <div className="p-numberWelcome">
      <NumberWelcomeTemplate urlIntro={SLUG_PARAMS.NUMBER_INSTRUCTION} />
    </div>
  );
};

export default NumberWelcome;
