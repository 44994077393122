/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  forwardRef, Fragment, useEffect, useState,
} from 'react';

import iconLoading from 'assets/images/loading.gif';
import Button from 'components/atoms/Button';
import Player, { PlayerProps } from 'components/organisms/Player';
import { BootLayerTypes } from 'services/configs/type';
import mapModifiers from 'utils/functions';

interface BoothDetailProps extends PlayerProps {
  modifiers?: string;
  showLayer?: boolean;
  layerClickable?: BootLayerTypes[];
  handleClick?: (val: BootLayerTypes) => void;
  handleBack?: () => void;
  handleNext?: () => void;
  soundButton?: React.ReactNode;
  order?: number;
}

const BoothDetail = forwardRef<HTMLVideoElement, BoothDetailProps>((
  {
    handleClick, handleBack, handleNext,
    showLayer, layerClickable, modifiers, soundButton,
    order,
    ...props
  }, ref,
) => {
  const [loading, setLoading] = useState(true);
  const [showBack, setShowBack] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (showLayer) setShowBack(true);
    }, 2000);
  }, [showLayer]);
  return (
    <>
      <div
        className={mapModifiers('t-boothDetail', modifiers)}
      >
        {/* {
            loading && (
              <div className="t-foyer_boothDetail_loading">
                <img src={iconLoading} alt="loading" />
              </div>
            )
          } */}
        <div className="t-boothDetail_video">
          <Player
            {...props}
            onLoaded={() => setLoading(false)}
            ref={ref}
          />
          {
            showLayer && layerClickable
            && (
              <div className="t-boothDetail_clickable">
                {
                  layerClickable.map((val, idx) => (
                    <Fragment key={`i-${idx.toString()}`}>
                      <div
                        className={`t-boothDetail_clickable_item item${order}`}
                        onClick={() => handleClick && handleClick(val)}
                      />
                      {/* <div
                        onClick={() => handleClick && handleClick(val)}
                        className={`t-boothDetail_clickable_panel item${idx + 1}`}
                      /> */}
                    </Fragment>
                  ))
                }
              </div>
            )
          }
        </div>
        {soundButton}
        {
          showBack
          && (
            <div className="t-boothDetail_back">
              <Button variant="secondary" size="sm" handleClick={handleNext}>
                ĐI TIẾP
              </Button>
            </div>
          )
        }
      </div>

    </>
  );
});

BoothDetail.defaultProps = {
  handleBack: undefined,
  handleNext: undefined,
};

export default BoothDetail;
