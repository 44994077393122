import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmojiIconTypes } from 'components/organisms/Emoji';
import getGeneralService from 'services/general';
import { GeneralTypes } from 'services/general/type';
import { GetMemberResponse } from 'services/member/types';
import { LuckyDrawsSocketTypes } from 'services/picking/type';

export type NumberSelectionData = {
  eventTimeCode: string
  number: number
  select: boolean
  startTime: number
  uid: number
}

interface GeneralState {
  data?: GeneralTypes;
  socketStatus?: number;
  loading?: boolean;
  luckyDrawsSocket: LuckyDrawsSocketTypes[];
  emojiSocket: EmojiIconTypes[];
  numberSocket: NumberSelectionData[];
  resetNumberSocket?: { numbers: number[] };
}

const initialState: GeneralState = {
  data: undefined,
  loading: false,
  luckyDrawsSocket: [],
  emojiSocket: [],
  numberSocket: [],
};

export const getGeneralAction = createAsyncThunk<
  GeneralTypes, void, { rejectValue: ErrorResponse }
>(
  'general/getGeneralAction', async (_, { rejectWithValue }) => {
    try {
      const res = await getGeneralService();
      return res;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setStatusSocket($state, action: PayloadAction<number>) {
      $state.socketStatus = action.payload;
    },
    addNewLuckyDrawsSocket: ($state, action: PayloadAction<LuckyDrawsSocketTypes>) => {
      const clone = [...$state.luckyDrawsSocket];
      $state.luckyDrawsSocket = [action.payload, ...clone];
    },
    addNewEmojiSocket: ($state, action: PayloadAction<EmojiIconTypes>) => {
      const clone = [...$state.emojiSocket];
      $state.emojiSocket = [action.payload, ...clone];
    },
    addNewNumberSocket: ($state, action: PayloadAction<{
      number: NumberSelectionData,
      userData?: GetMemberResponse
    } | undefined>) => {
      const { payload } = action;
      if (payload && payload.number.uid !== payload.userData?.id
        && payload.number.eventTimeCode === payload.userData?.event_time_code) {
        const clone = [...$state.numberSocket];
        $state.numberSocket = [payload.number, ...clone];
      }
    },
    addResetNumberSocket: ($state, action: PayloadAction<{ numbers: number[] }>) => {
      $state.resetNumberSocket = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getGeneralAction.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getGeneralAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
    });
    builder.addCase(getGeneralAction.rejected, ($state) => {
      $state.loading = false;
    });
  },
});

export const {
  setStatusSocket,
  addNewLuckyDrawsSocket,
  addNewEmojiSocket,
  addNewNumberSocket,
  addResetNumberSocket,
} = generalSlice.actions;

export default generalSlice.reducer;
