/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// import banner from 'assets/images/ver-banner-2.jpg';
import background from 'assets/images/banner_login.jpg';
import backgroundMobile from 'assets/images/banner_login_mb.jpg';
import Image from 'components/atoms/Image';
import useWindowSize from 'hooks/useWindowSize';
import { getInfoMemberService } from 'services/member';
import { MemberInfoData } from 'services/member/types';

const Schedule2: React.FC = () => {
  const slugParams = useParams<{ code: string }>();
  const [prizeData, setPrizeData] = useState<MemberInfoData>();
  const [errors, setErrors] = useState<string>('');
  const { width } = useWindowSize();

  useEffect(() => {
    (async () => {
      try {
        const data = await getInfoMemberService({
          code: slugParams.code,
        });
        if (data) {
          setPrizeData(data);
        }
      } catch {
        setErrors('Đường dẫn không đúng');
      }
    })();
  }, [slugParams.code]);

  return (
    <div className="t-numberLogin">
      {/* LOGO  */}
      {/* <div className="t-numberLogin_logo">
        <img src={logo} alt="logo" />
      </div> */}
      {/* BACKGROUND  */}
      <div className="t-numberLogin_background">
        <Image
          imgSrc={background}
          srcMobile={backgroundMobile}
          srcTablet={backgroundMobile}
          size="cover"
          alt="background"
          ratio="1366x768"
        />
      </div>
      {/* FORM  */}
      <div className="p-schedule2_invitation">
        <div className="p-schedule2_invitation_title"><span>MÃ THAM DỰ SỰ KIỆN</span></div>

        { !errors && (
          <div className="p-schedule2_code">
            <QRCodeSVG value={prizeData?.shop_code || ''} size={width > 767 ? 320 : 200} className="p-schedule_code_qr" />
          </div>
        )}

        { errors ? <p className="p-schedule_error">{errors}</p> : (
          <div className="p-schedule2_store">
            <p>{`Tên cửa hàng: ${prizeData?.shop_name || ''}`}</p>
            <p>{`Mã cửa hàng: ${prizeData?.shop_code || ''}`}</p>
          </div>
        )}
        <div className="p-schedule2_contact">TỔNG ĐÀI LIÊN HỆ: 0918 45 40 49</div>
      </div>
    </div>
  );
};

export default Schedule2;
