/* eslint-disable react/no-danger */
import React, { forwardRef, useRef } from 'react';

import iconSearch from 'assets/icons/ic_search.svg';
import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

type Modifier = 'otp' | 'secondary' | 'light' | 'search';
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  name?: string;
  type?: 'text' | 'number' | 'email' | 'password' | 'tel';
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  dark?: boolean;
  maxLength?: number;
  noCursor?: boolean;
  handleChange?: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur?: React.ChangeEventHandler<HTMLInputElement>;
  handleFocus?: React.ChangeEventHandler<HTMLInputElement>;
  handleClear?: () => void;
  modifiers?: Modifier | Modifier[];
}

const InputRef: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    id,
    noCursor,
    label,
    type = 'text',
    name = '',
    error,
    placeholder = '',
    disabled = false,
    maxLength,
    value = '',
    dark = false,
    onKeyUp,
    handleChange = () => {},
    handleBlur = () => {},
    handleFocus = () => {},
    modifiers,
    ...rest
  },
  ref,
) => {
  const inputRef = useRef(null);

  return (
    <div className={mapModifiers('a-input', modifiers)} ref={inputRef}>
      {label && (
        <div className="a-input_label">
          {
            (modifiers?.includes('secondary') || modifiers?.includes('light'))
              ? <Text modifiers={['16x22', 'pigment']}>{label}</Text>
              : <Text modifiers={['md-sm', 'center', 'pigment', '700']}>{label}</Text>
          }

        </div>
      )}
      <div className="a-input_container">
        {
          modifiers === 'search'
          && <img src={iconSearch} alt="search" />
        }
        <input
          id={id}
          name={name}
          className={mapModifiers(
            'a-input_inputele',
            type,
            error && 'error',
            dark && 'dark',
            noCursor && 'noCursor',
          )}
          type={type}
          ref={ref}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onKeyUp={onKeyUp}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          pattern={type === 'number' ? '\\d*' : undefined}
          {...rest}
        />
      </div>
      {error && (
        <span
          className="a-input_errorMessage"
          dangerouslySetInnerHTML={{ __html: error }}
        />
      )}
    </div>
  );
};

const Input = forwardRef(InputRef);

export default Input;
