import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeAction } from 'store/me';
import { GA_ID, GTM_ID } from 'utils/constants';

const AppInitializer: React.FC = () => {
  const location = useLocation();
  const [initializedGA, setInitializedGA] = useState(false);
  const { data: meData } = useAppSelector((state) => state.me);
  const [countdownTimer, setCountdownTimer] = useState(1000);
  const { data: systemData } = useAppSelector((state) => state.general);

  const dispatch = useAppDispatch();
  //* GTM-GA
  useEffect(() => {
    ReactGA.initialize(GA_ID);
    setInitializedGA(true);

    TagManager.initialize({
      gtmId: GTM_ID,
    });
  }, []);

  const minArr = useMemo(() => [meData?.event_time.date_end_remaining || 0,
    meData?.event_time.end_conference_remaining || 0,
    meData?.event_time.start_conference_remaining || 0,
    meData?.event_time.timeout_livestream_remaining || 0,
    meData?.event_time.date_start_remaining || 0,
  ], [meData]);

  const minRemaining = useMemo(() => minArr.filter((f) => f > 0), [minArr]);

  useEffect(() => {
    setCountdownTimer(Math.min(...minRemaining));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdownTimer(countdownTimer - 1);
    }, 1000);

    if (meData && systemData && countdownTimer === 0) {
      clearInterval(intervalId);
      setTimeout(() => {
        dispatch(getMeAction());
      }, 500);
    }
    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTimer, systemData, meData]);

  useEffect(() => {
    if (initializedGA && location) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initializedGA, location]);
  //* End GTM-GA
  return null;
};

export default AppInitializer;
