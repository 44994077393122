import moment from 'moment';

function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>, minus?: number) {
  if (ref && ref.current) {
    window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - (minus || 0) }); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}

export const getSecondsResendOTP = (date: Date) => {
  const today = new Date();
  const different = date.getTime() - today.getTime();
  return different < 0
    ? 90
    : Math.floor((different / 1000) % 60);
};

export const randomNumberInRange = (
  min: number, max: number,
) => min + (Math.random() * (max - min));

export const formatDateTimeEvent = (datetime?: string) => {
  if (!datetime) return '';
  const fDatetime = moment(datetime);
  const hours = fDatetime.hours();

  const day = fDatetime.date() >= 10 ? fDatetime.date() : `0${fDatetime.date()}`;
  const month = fDatetime.month() + 1 >= 10 ? fDatetime.month() + 1 : `0${fDatetime.month() + 1}`;
  const year = fDatetime.year();
  return `${hours}h ngày ${day}/${month}/${year}`;
};

export const formatDateEvent = (datetime?: string) => {
  if (!datetime) return '';
  const fDatetime = moment(datetime);

  const day = fDatetime.date() >= 10 ? fDatetime.date() : `0${fDatetime.date()}`;
  const month = fDatetime.month() + 1 >= 10 ? fDatetime.month() + 1 : `0${fDatetime.month() + 1}`;
  const year = fDatetime.year();
  return `Ngày ${day}/${month}/${year}`;
};

export const numberWithCommas = (
  num?: number,
) => (num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0);

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  // const mime = arr[0].match(/:(.*?);/)[1];
  const mine = 'image/jpeg';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mine });
};

export function getRandomArbitrary(min:number, max:number) {
  return Math.random() * (max - min) + min;
}
export const imageLoader = (url: string) => new Promise<HTMLImageElement>((resolve, reject) => {
  const img = new Image();

  img.crossOrigin = 'anonymous';

  img.onload = () => {
    resolve(img);
  };
  img.onerror = (err) => {
    reject(err);
  };
  img.src = url;
});

export const addZeroPrefixNumberPositive = (num?: number) => {
  if (!num || num < 0) return '00';
  if (num > 9) return num;
  return `0${num}`;
};
