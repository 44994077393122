import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ForgetPassword, {
  ForgetPasswordFormTypes,
} from 'components/templates/ForgetPassword';
import ResetPasswordSuccess from 'components/templates/ResetPasswordSuccess';
import { useAsync } from 'hooks/useAsync';
import { forgotPasswordService, getMemberService } from 'services/member';
import SLUG_PARAMS from 'utils/constants';

const ForgotPasswordContainer: React.FC = () => {
  const history = useHistory();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isShowPopupSuccess, setShowPopupSuccess] = useState(false);
  const [isDisabledBtn, setDisabledBtn] = useState(true);

  const [forgotPasswordExecute, forgotPasswordState] = useAsync(
    (params: ForgetPasswordFormTypes) => forgotPasswordService(params.code),
    {
      onSuccess: () => {
        setShowPopupSuccess(true);
      },
    },
  );

  const validateField = async (value: string) => {
    try {
      const res = await getMemberService(value);
      setPhone(res.phone);
      setName(res.name);
      setDisabledBtn(false);
    } catch {
      setPhone('');
      setName('');
      setDisabledBtn(true);
    }
  };

  return (
    <>
      <ForgetPassword
        name={name}
        phone={phone}
        loading={forgotPasswordState.status === 'PENDING'}
        disabled={isDisabledBtn}
        validateField={validateField}
        handleSubmit={forgotPasswordExecute}
      />

      {/* popup  */}
      <ResetPasswordSuccess
        phoneNumber={phone}
        openPopup={isShowPopupSuccess}
        handleClose={() => setShowPopupSuccess(false)}
        handleClick={() => history.push(SLUG_PARAMS.NUMBER_LOGIN)}
      />
    </>
  );
};

export default ForgotPasswordContainer;
