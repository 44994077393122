/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import Text from 'components/atoms/Text';
import ContextPopup from 'components/templates/ContextPopup';
import Selfie from 'components/templates/Selfie';
import { useDidMount } from 'helpers/react-hook';

const SelfieContainer: React.FC = () => {
  const [permission, setPermission] = useState<PermissionState>('prompt');
  const [noticePopup, setNoticePopup] = useState(false);
  // useDidMount(() => {
  //   (async () => {
  //     try {
  //       await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
  //       stream.getTracks().forEach((track) => {
  //         track.stop();
  //       });
  //       setPermission('granted');
  //     } catch (error) {
  //       setPermission('denied');
  //       setNoticePopup(true);
  //     }
  //   })();
  // });
  return (
    <>
      <Selfie />
      <ContextPopup
        openPopup={noticePopup}
        handleClose={() => setNoticePopup(false)}
      >
        <Text modifiers={['700', 'pigment']}>
          Camera không có sẵn. Vui lòng thử lại hoặc cho phép sử dụng camera!
        </Text>
      </ContextPopup>
    </>
  );
};

export default SelfieContainer;
