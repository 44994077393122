import React from 'react';

import DeviceOrientImage from 'assets/images/orient-device.png';
import Text from 'components/atoms/Text';
import useWindowDimensions from 'hooks/useWindowDimension';

interface DeviceWrappedProps {
  children?: React.ReactNode;
}

const DeviceWrapped = ({
  children,
}: DeviceWrappedProps) => {
  const { isMobile, isLandscape } = useWindowDimensions();

  return (
    <>
      {isMobile && !isLandscape ? (
        <div className="t-devicewrapped">
          <img alt="mobile" src={DeviceOrientImage} />
          <Text modifiers={['white', 'center']}>
            Vui lòng xoay
            {' '}
            ngang
            {' '}
            màn hình điện thoại để trải nghiệm sự kiện một cách tốt nhất.
            <br />
            Xin Cảm ơn!
          </Text>
        </div>
      )
        : children}
    </>
  );
};

DeviceWrapped.defaultProps = {
  children: <div />,
};

export default DeviceWrapped;
