/* eslint-disable react/require-default-props */
import React from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Ball from 'components/molecules/Ball';
import Popup from 'components/organisms/Popup';
import { BallType } from 'components/templates/PickingBall';
import { addZeroPrefixNumberPositive } from 'utils/functions';

interface PopupSelectedBallProps {
  isShowPopup: boolean;
  activeBall?: Array<BallType>;
  limitBall?: number;
  handleClose?: () => void;
}

const PopupSelectedBall: React.FC<PopupSelectedBallProps> = ({
  isShowPopup,
  activeBall,
  limitBall,
  handleClose,
}) => (
  <Popup
    modifiers="selected-ball"
    isOpenModal={isShowPopup}
    title="CÁC SỐ DỰ THƯỞNG ĐÃ CHỌN"
    handleClose={handleClose}
    hasCloseButton
  >
    <Text modifiers={['16x22', 'jet']}>
      Quý khách vui lòng chọn tất cả các số dự thưởng
    </Text>
    <div className="u-mt-12 u-mb-24">
      <Text modifiers={['16x22', 'pigment']}>
        Tổng các số dự thưởng đã chọn của quý khách là:
        {' '}
        <strong>
          {addZeroPrefixNumberPositive(activeBall?.length)}
          /
          {addZeroPrefixNumberPositive(limitBall)}
        </strong>
      </Text>
    </div>
    <div className="t-selectedBall_list">
      {activeBall?.map((e, i) => (
        <div className="t-selectedBall_list_item" key={`selected-ball${i.toString()}`}>
          <Ball {...e} variant="active" size={72} />
        </div>
      ))}
    </div>
    <div className="submit">
      <Button handleClick={handleClose}>
        QUAY LẠI
      </Button>
    </div>
  </Popup>
);

PopupSelectedBall.defaultProps = {
  handleClose: undefined,
  limitBall: 0,
  activeBall: [],
};

export default PopupSelectedBall;
