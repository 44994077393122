import React from 'react';

import Text from 'components/atoms/Text';
import HeadingSection from 'components/molecules/HeadingSection';
import Container from 'components/organisms/Container';

export interface GuideItemTypes {
  title: string;
  informations: string[];
}

export interface GuideProps {
  contentsTop: GuideItemTypes;
  contentsBottom: GuideItemTypes;
}

const GuideItem: React.FC<GuideItemTypes> = ({ title, informations }) => (
  <div className="t-guide_item">
    <div className="t-guide_titleGroup">
      <div className="t-guide_title">
        <HeadingSection>{title}</HeadingSection>
      </div>
    </div>
    <div className="t-guide_content">
      <ul>
        {informations && informations.length > 0 && (
          <>
            {informations.map((ele, idx) => (
              <li key={`i-${idx.toString()}`}>
                <Text
                  modifiers={['md-sm', '400', 'pigment']}
                  type="div"
                  content={ele}
                />
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  </div>
);

const Guide: React.FC<GuideProps> = ({ contentsTop, contentsBottom }) => (
  <div className="t-guide">
    <Container>
      <div className="t-guide_inner">
        <GuideItem
          title={contentsTop.title}
          informations={contentsTop.informations}
        />
        <GuideItem
          title={contentsBottom.title}
          informations={contentsBottom.informations}
        />
      </div>
    </Container>
  </div>
);
Guide.defaultProps = {};

export default Guide;
