import React from 'react';

import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import CardNews, { CardNewsTypes } from 'components/molecules/CardNews';
import mapModifiers from 'utils/functions';

export interface EventsProps {
  imgSrc: string;
  subTitle: string;
  title: string;
  cardNews: CardNewsTypes;
  useImageRightHalf?:boolean
}

const Events: React.FC<EventsProps> = ({
  imgSrc,
  subTitle,
  title,
  cardNews,
  useImageRightHalf,
}) => (
  <div
    className={mapModifiers(
      'o-events',
      useImageRightHalf && 'useImageRightHalf',
    )}
  >
    <div className="o-events_image">
      <Image size="cover" alt="img" imgSrc={imgSrc} ratio="505x397" />
    </div>
    <div className="o-events_content">
      <div className="o-events_heading">
        <Text modifiers={['md', '400', 'pigment']} type="span">
          {subTitle}
        </Text>
        <Text modifiers={['md', '900', 'blueLinear']} type="span">
          {title}
        </Text>
      </div>
      <div className="o-events_cardnews">
        <CardNews date={cardNews.date} informations={cardNews.informations} />
      </div>
    </div>
  </div>
);

export default Events;
