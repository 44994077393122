/* eslint-disable react/no-unused-prop-types */
import React from 'react';

// import logoImg from 'assets/images/logo-brand.png';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import useDeviceQueries from 'hooks/useDeviceQueries';
// import useWindowSize from 'hooks/useWindowSize';
import mapModifiers from 'utils/functions';

interface BannerProps {
  title?: string;
  imageDesktop: string;
  imageMobile?: string;
  imageTablet?: string;
  handleClick?: () => void;
  handleClickInfo?: () => void;
  hasInner?: boolean;
  outSide?: React.ReactNode;
}

const Banner: React.FC<BannerProps> = ({
  imageDesktop,
  imageMobile,
  imageTablet,
  title,
  handleClick,
  hasInner,
  outSide,
}) => {
  const { isMobile } = useDeviceQueries();
  // const { height } = useWindowSize();
  return (
    <div className={mapModifiers('o-banner')}>
      {/* <div className="o-banner_logo">
        <img src={logoImg} alt="logoImg" />
      </div> */}
      <div className="o-banner_wrapper">
        <div className="o-banner_background">
          <Image
            imgSrc={imageDesktop}
            srcTablet={imageTablet}
            srcMobile={imageMobile}
            alt="background"
            size="cover"
            ratio={isMobile ? '375x812' : '1360x768'}
          />
        </div>
        <div className="o-banner_layer" />

        {hasInner && (
        <div className="o-banner_inner">
          {/* <div className="o-banner_button mobile">
            <Button color="pigment" handleClick={handleClickInfo}>
              THÔNG TIN CHƯƠNG TRÌNH
            </Button>
          </div> */}
          <div className="o-banner_button">
            <Button color="pigment" handleClick={handleClick}>
              {title}
            </Button>
          </div>
        </div>
        )}
        {outSide}
      </div>
    </div>
  );
};
Banner.defaultProps = {
  imageTablet: undefined,
  imageMobile: undefined,
  handleClick: undefined,
  handleClickInfo: undefined,
  hasInner: true,
  outSide: undefined,
  title: undefined,
};

export default Banner;
