import { GuideItemTypes } from 'components/templates/Guide';

const contentsTopData: GuideItemTypes = {
  title: 'HƯỚNG DẪN CHỌN SỐ DỰ THƯỞNG',
  informations: [
    'Trước hội nghị, hệ thống sẽ mở vào ngày <b>05/01/2025</b> để quý khách chọn số.',
    'Quý khách có 01 ngày để chọn số từ khi hệ thống được mở.',
    'Quý khách truy cập trang web:<a href="https://hoinghikhachhangclp.com" target="_blank"><b> https://hoinghikhachhangclp.com </b></a> hoặc quét mã QR in trên thiệp mời để vào trang chọn số.',
    'Nhập <b> Tên truy cập </b> và <b> Mật khẩu </b> đã được gửi trong tin nhắn xác nhận để đăng nhập.',
    'Chọn số dự thưởng theo hướng dẫn từ trang web.',
    'Mỗi tài khoản phải chọn tất cả số dự thưởng trong 1 lượt đăng nhập duy nhất.',
    'Kết thúc thời gian chọn số, hệ thống sẽ gửi tin nhắn xác nhận số đã chọn đến quý khách.',
    'Nếu có vấn đề phát sinh hoặc thắc mắc, quý khách vui lòng liên hệ tổng đài <a href="tel:0918454049" style="white-space: nowrap;"><b> 0918 45 40 49 </b></a> để được hỗ trợ.',
  ],
};

export const contentsBottomData: GuideItemTypes = {
  title: 'HƯỚNG DẪN THAM gia buổi trực tuyến bỏ banh vào lồng cầu',
  informations: [
    'Quý khách truy cập trang web: <a href="https://hoinghikhachhangclp.com" target="_blank"><b> https://hoinghikhachhangclp.com </b></a> hoặc quét mã QR in trên thiệp mời để vào tham dự hội nghị.',
    'Nhập <b> Tên truy cập </b> và <b> Mật khẩu </b> đã được gửi trong tin nhắn xác nhận để đăng nhập và theo dõi chương trình.',
    'Nếu có vấn đề phát sinh hoặc thắc mắc, quý khách vui lòng liên hệ tổng đài <a href="tel:0918454049" style="white-space: nowrap;"><b> 0918 45 40 49 </b></a>để được hỗ trợ.',
  ],
};

export default contentsTopData;
