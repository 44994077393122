import React, { useMemo } from 'react';

import useDeviceQueries from 'hooks/useDeviceQueries';
import mapModifiers from 'utils/functions';

export type SizeImageType = 'cover' | 'contain' | 'inherit' | 'initial';

interface ImageProps {
  imgSrc: string;
  srcTablet?: string;
  srcMobile?: string;
  alt: string;
  ratio: Ratio;
  size: SizeImageType;
  modifiers?: 'normal';
}

const Image: React.FC<ImageProps> = ({
  imgSrc, srcTablet, srcMobile, alt, ratio, size, modifiers,
}) => {
  const { isMobile, isTablet } = useDeviceQueries();
  const sourceImage = useMemo(() => {
    if (isMobile) {
      return srcMobile || imgSrc;
    }
    if (isTablet) {
      return srcTablet || imgSrc;
    }
    return imgSrc;
  }, [isMobile, isTablet, imgSrc, srcMobile, srcTablet]);
  return (
    <div
      className={mapModifiers('a-image', ratio, size, modifiers)}
    >
      <img src={sourceImage} alt={alt} loading="lazy" />
    </div>
  );
};

Image.defaultProps = {
  srcTablet: undefined,
  srcMobile: undefined,
  modifiers: undefined,
};

export default Image;
