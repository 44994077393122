const dataHeaderTable = [
  {
    id: '1',
    value: 'STT',
  },
  {
    id: '2',
    value: 'TÊN CỬA HÀNG',
  },
  {
    id: '3',
    value: 'SỐ TRÚNG THƯỞNG',
  },
];

export const dataTableDummy = [
  {
    id: 1,
    name: 'CH NGUYỄN THÀNH CHUNG',
    address: 'ĐC: 01 Hai Bà Trưng, Quận 1, Tp. Hồ Chí Minh',
    number: 1028,
  },
  {
    id: 2,
    name: 'CH NGUYỄN THÀNH CHUNG',
    address: 'ĐC: 01 Hai Bà Trưng, Quận 1, Tp. Hồ Chí Minh',
    number: 1030,
  },
  {
    id: 3,
    name: 'CH NGUYỄN THÀNH CHUNG',
    address: 'ĐC: 01 Hai Bà Trưng, Quận 1, Tp. Hồ Chí Minh',
    number: 1029,
  },
];

export const dataHeaderSecondaryTable = [
  {
    id: '1',
    value: 'STT',
  },
  {
    id: '2',
    value: 'SỐ TRÚNG THƯỞNG',
  },
  {
    id: '3',
    value: 'KHÁCH HÀNG',
  },
  {
    id: '4',
    value: 'ĐỊA CHỈ',
  },
];

export const dataSecondaryTableDummy = [
  {
    id: 1,
    name: 'THANH hiền',
    address: 'H.BÌNH chánh, Hồ chí minh',
    number: 1028,
  },
  {
    id: 2,
    name: 'THANH hiền',
    address: 'H.BÌNH chánh, Hồ chí minh',
    number: 1028,
  },
  {
    id: 3,
    name: 'THANH hiền',
    address: 'H.BÌNH chánh, Hồ chí minh',
    number: 1028,
  },
  {
    id: 4,
    name: 'THANH hiền',
    address: 'H.BÌNH chánh, Hồ chí minh',
    number: 1028,
  },
  {
    id: 5,
    name: 'THANH hiền',
    address: 'H.BÌNH chánh, Hồ chí minh',
    number: 1028,
  },
];

export default dataHeaderTable;
