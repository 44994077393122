const SLUG_PARAMS = {
  HOME: '/sadasdasdasd',
  REGISTER: '/',
  // REGISTER: '/dang-ky',
  REGISTER_V2: '/dang-ky2',
  NUMBER_LOGIN: '/dang-nhap-chon-so',
  FORGET_PASSWORD: '/quen-tai-khoan',
  NUMBER_WELCOME: '/gioi-thieu-chon-so',
  NUMBER_INSTRUCTION: '/huong-dan-chon-so',
  NUMBER_PICKING: '/chon-so',
  NUMBER_CONGRATULATION: '/chuc-mung',
  NUMBER_INVITATION: '/thiep-moi',
  WAITING: '/trang-cho',
  FOYER: '/foyer',
  LIVESTREAM: '/livestream',
  ENDING: '/ket-thuc',
  LOGOUT: '/logout',
  CHECKIN_START: '/checkin-start',
  CHECKIN: '/checkin',
  AWARD_ANNOUNCEMENT: '/thong-bao-giai-thuong',
  PRIZE: '/giai-thuong',
  SPECIAL_PRIZE: '/giai-dac-biet',
  CHECKIN1: '/checkin-v1',
  CHECKIN2: '/checkin-v2',
};

export const STATUC_SLUG = ['dang-ky2', 'dang-nhap-chon-so', 'quen-tai-khoan', 'gioi-thieu-chon-so', 'huong-dan-chon-so', 'chon-so', 'chuc-mung', 'thiep-moi', 'trang-cho', 'foyer', 'livestream', 'ket-thuc', 'checkin-start', 'checkin', 'thong-bao-giai-thuong', 'giai-thuong', 'giai-dac-biet', 'checkin-v1', 'checkin-v2'];

export const GA_ID = 'G-STFX347TQ3';
export const GTM_ID = 'G-Y32PE34Z1J';
export const STORAGE_KEY = {
  NUMBER_PICKED: 'numberPicked',
};

export const NUMBER_PICKING_TIMER = 20;
export default SLUG_PARAMS;
