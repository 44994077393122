import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CheckInTypes } from './type';

import getEventTimesService from 'services/checkin';
import { EventTimesTypes } from 'services/checkin/type';

interface CheckInState {
  data?: CheckInTypes;
  eventData?: EventTimesTypes[];
}

const initialState: CheckInState = {
  data: undefined,
  eventData: [],
};

export const getEventTimeAction = createAsyncThunk<
EventTimesTypes[], void, {rejectValue: ErrorResponse}
>(
  'eventData/getEventTimeAction', async (_, { rejectWithValue }) => {
    try {
      const res = await getEventTimesService();
      return res;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

export const checkInSlice = createSlice({
  name: 'checkIn',
  initialState,
  reducers: {
    getCheckInStatus($state, action) {
      $state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getEventTimeAction.fulfilled, ($state, action) => {
      $state.eventData = action.payload;
    });
  },
});

export const { getCheckInStatus } = checkInSlice.actions;

export default checkInSlice.reducer;
