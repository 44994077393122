import React from 'react';

import Link from 'components/atoms/Link';
import mapModifiers from 'utils/functions';

export const iconList = {
  location: 'location',
  time: 'time',
  sound: 'sound',
  mute: 'mute',
  soundBlue: 'soundBlue',
  muteBlue: 'muteBlue',
  search: 'search',
  expand: 'expand',
  minimize: 'minimize',
};

export type IconSize = '20x20' | '24x24' | '32x32' | 'big';
export type IconName = keyof typeof iconList;

interface IconProps {
  iconName: IconName;
  iconSize?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, iconSize }) => (
  <i className={mapModifiers('a-icon', iconName, iconSize)} />
);
interface IconButtonProps extends IconProps {
  href?: string;
  target?: string;
  bgColor?: 'white';
  buttonSize?: '26' | '30'| '37';
  handleClick?: () => void;
}

export const IconButton:React.FC<IconButtonProps> = ({
  iconName,
  iconSize,
  href,
  target,
  bgColor,
  buttonSize,
  handleClick,
}) => {
  if (href) {
    return (
      <Link className={mapModifiers('a-iconButton', bgColor, buttonSize)} href={href} target={target} aria-label="button">
        <Icon iconName={iconName} iconSize={iconSize} />
      </Link>
    );
  }
  return (
    <button className={mapModifiers('a-iconButton', bgColor, buttonSize)} type="button" onClick={handleClick}>
      <Icon iconName={iconName} iconSize={iconSize} />
    </button>
  );
};

IconButton.defaultProps = {
  handleClick: undefined,
  href: undefined,
  target: undefined,
  iconSize: undefined,
  bgColor: undefined,
  buttonSize: undefined,
};

Icon.defaultProps = {
  iconSize: undefined,
};

export default Icon;
