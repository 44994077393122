import React, { useCallback, useRef, useState } from 'react';

import BoothDetail from 'components/templates/BoothDetail';
import PlayerPopup from 'components/templates/PlayerPopup';
import useMutedAutoplay from 'hooks/useMutedAutoplay';

const Test: React.FC = () => {
  const backgroundVideoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [seenVideo, setSeenVideo] = useState(false);

  useMutedAutoplay(backgroundVideoRef, setIsMuted);

  const videoBackgroundTimeUpdate = useCallback((val: number) => {
    const video = backgroundVideoRef.current;
    if (video) {
      if (val >= 10 && !seenVideo) {
        video.pause();
        setOpenPopup(true);
        setSeenVideo(true);
      }
    }
  }, [seenVideo]);
  return (
    <div>
      <BoothDetail
        onTimeUpdate={videoBackgroundTimeUpdate}
        ref={backgroundVideoRef}
        isMuted={isMuted}
        layerClickable={[]}
        src="https://cdn.hoinghikhachhangclp.com/static/clp-2021/hanhtrinh1.720.mp4"
      />
      <PlayerPopup
        isOpen={openPopup}
        handleNext={() => {
          if (backgroundVideoRef.current) {
            backgroundVideoRef.current.play();
            setOpenPopup(false);
          }
        }}
        mediaSrc="https://cdn.hoinghikhachhangclp.com/static/clp-2021/hanhtrinh1.720.mp4"
      />

    </div>
  );
};

export default Test;
