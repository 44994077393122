import React from 'react';

import DeviceWrapped from 'components/templates/DeviceWrapped';
import LivestreamContainerWrapper from 'containers/Livestream';

const Livestream: React.FC = () => (
  <div className="p-livestream">
    <DeviceWrapped>
      <LivestreamContainerWrapper />
    </DeviceWrapped>
  </div>
);

export default Livestream;
