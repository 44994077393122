/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { MenuFoyerTypes } from '../Foyer/menu';
import InvitationCard from '../InvitationCard';

import Icon from 'components/atoms/Icon';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import { BootLayerTypes, FoyerConfigData } from 'services/configs/type';
import { GetMemberResponse } from 'services/member/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeAction } from 'store/me';
import SLUG_PARAMS from 'utils/constants';

interface FoyerAioProps {
  foyerData?: FoyerConfigData;
  menuData?: MenuFoyerTypes[];
  meData: GetMemberResponse;
}

const SoundControl: React.FC<{ isMuted: boolean, handleSound: () => void }> = (
  { isMuted, handleSound },
) => (
  <div
    className={`t-foyer_sound${isMuted ? ' muted' : ''}`}
    onClick={handleSound}
  >
    <Icon iconName={isMuted ? 'muteBlue' : 'soundBlue'} iconSize="big" />
    <p>BẬT ÂM THANH</p>
  </div>
);

const FoyerAio: React.FC<FoyerAioProps> = ({
  foyerData, menuData, meData,
}) => {
  const { data: profileData } = useAppSelector((state) => state.me);
  const [isMuted, setIsMuted] = useState(false);
  const [mainStep, setMainStep] = useState(1);
  const history = useHistory();
  const backgroundVideoRef = useRef<HTMLVideoElement>(null);
  const [boothDetailLayer, setBoothDetailLayer] = useState<BootLayerTypes>();
  /**
   * BOOTH NUMBER
   * booth 1: Similac
   * booth 2: Abbott
   * booth 3: Pediasure
   * booth 4: Ensure
   * booth 5: Glucerna
   */
  const dispatch = useAppDispatch();
  const { data: systemData } = useAppSelector((state) => state.general);
  const [boothIndex, setBoothIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [liveSocket, setLiveSocket] = useState(false);
  const [liveStatus, setLiveStatus] = useState<'live'| 'livefoyer' | 'nolive'>('nolive');
  const [finishVideo, setFinishVideo] = useState(false);
  const [arrCode, setArrCode] = useState<string[]>();
  const [boothTracking, setBoothTracking] = useState(
    [...Array(foyerData?.booth_video.length)].fill(false),
  );
  const [openPopup, setOpenPopup] = useState({
    confirmSelfie: false, // -------step 1
    selfie: false,
    hallVideo: false,
    layerHallVideo: false,
    boothPlayer: false, // ------step 2
    introBooth: false,
    layerBooth: false,
    quiz: false,
    introAgenda: false,
  });
  const [videoSeen, setVideoSeen] = useState({
    selfieTrack: false,
    videoHallTrack: false,
    introBooth1Track: false, // ------step 2
    introBooth2Track: false,
    introBooth3Track: false,
    introBooth4Track: false,
    introBooth5Track: false,
    layerBoothTrack: false,
    quizTrack: false,
    introAgendaTrack: false,
  });
  const routesValidRedirect = ['FOYER', 'LIVESTREAM'];
  const [countdownTimer, setCountdownTimer] = useState(1000);

  useMutedAutoplay(backgroundVideoRef, setIsMuted);

  const stepTag = useMemo(() => {
    switch (mainStep) {
      case 1:
        return 'hall';
      case 2:
        return 'exhibition';
      case 3:
        return 'agenda';

      default:
        return '';
    }
  }, [mainStep]);

  useEffect(() => {
    if (mainStep) {
      setTimeout(() => {
        setOpenPopup((prev) => ({
          ...prev,
          confirmSelfie: false, // -------step 1
          selfie: false,
          hallVideo: false,
          layerHallVideo: false,
          boothPlayer: false, // ------step 2
          introBooth: false,
          layerBooth: false,
        }));
      }, 500);
    }
  }, [mainStep]);

  /**
   * ------------BACKGROUND VIDEO CONTROL--------------
   */
  const videoBackgroundTimeUpdate = (time: number) => {
    if (!foyerData) return;
    /**
     * tracking step
     * 1: Hall
     * 2: Exhibition
     * 3: Agenda
     */
    if (time >= 0 && time < foyerData.timecode_menu[1]) {
      setMainStep(1);
    } else if (time >= foyerData.timecode_menu[1] && time < foyerData.timecode_menu[2] - 2) {
      setMainStep(2);
    } else setMainStep(3);

    const video = backgroundVideoRef.current;
    if (video) {
      // ------------------------------------------
      // --------------STEP 1: HALL----------------//
      // ------------------------------------------
      // open selfie
      if (mainStep === 1) {
        if ((time >= foyerData.timecode_tracking[0].value && !videoSeen.selfieTrack)) {
          video.pause();
          setOpenPopup((prevState) => ({
            ...prevState,
            confirmSelfie: true,
          }));
        }
        // open video
        if ((time > foyerData.timecode_tracking[1].value && !videoSeen.videoHallTrack)) {
          video.pause();
          setOpenPopup((prevState) => ({
            ...prevState,
            layerHallVideo: true,
          }));
        }
      }
      // ------------------------------------------------
      // --------------STEP 2: EXHIBITION----------------//
      // ------------------------------------------------
      if (mainStep === 2) {
        // ---------------- INTRO POPUP ----------------
        if (time >= foyerData.timecode_tracking[2].value && !videoSeen.introBooth1Track) {
          // setBoothName('Similac');
          video.pause();
          setOpenPopup((prevState) => ({
            ...prevState,
            introBooth: true,
          }));
          setVideoSeen((prev) => ({
            ...prev,
            introBooth1Track: true,
          }));
        }

        // ---------------- QUIZ ----------------
        if (time >= foyerData.timecode_tracking[3].value
          && !videoSeen.quizTrack && mainStep === 2) {
          video.pause();
          setOpenPopup((prevState) => ({
            ...prevState,
            quiz: true,
          }));
          setVideoSeen((prev) => ({
            ...prev,
            quizTrack: true,
          }));
        }

        foyerData.booth_video.forEach((val, idx) => {
          if (time >= val.timecode && !boothTracking[idx]) {
            setBoothIndex(idx);
            setOpenPopup((prevState) => ({
              ...prevState,
              layerBooth: true,
            }));
            const clone = [...boothTracking];
            clone[idx] = true;
            setBoothTracking(clone);
            setTimeout(() => {
              video.pause();
            }, 2000);
          }
        });
      }
      // ------------------------------------------------------
      // ---------------- STEP 3: INTRO AGENDA ----------------
      // ------------------------------------------------------
      if (time >= foyerData.timecode_tracking[4].value && !videoSeen.introAgendaTrack) {
        video.pause();
        setOpenPopup((prevState) => ({
          ...prevState,
          introAgenda: true,
        }));
        setVideoSeen((prev) => ({
          ...prev,
          introAgendaTrack: true,
        }));
        setArrCode(meData.redirect_to_page);
        setFinishVideo(true);
        // if (meData.redirect_to_page.includes('LIVESTREAM')) {
        //   setLiveSocket(true);
        // }
      }
    }
  };

  const playBackgroundVideo = useCallback((currentTime?: number) => {
    const video = backgroundVideoRef.current;
    if (video) {
      if (currentTime) {
        backgroundVideoRef.current.currentTime = currentTime;
        video.play();
      } else {
        video.play();
      }
    }
  }, [backgroundVideoRef]);

  /**
   * POPUP CONTROL
   */
  /**
   * SELFIE CONFIRM ACTION
   */
  const cancelConfirmSelfie = () => {
    setOpenPopup((prevState) => ({
      ...prevState,
      confirmSelfie: false,
    }));
    setVideoSeen((prevState) => ({
      ...prevState,
      selfieTrack: true,
    }));
    if (backgroundVideoRef.current) backgroundVideoRef.current.play();
  };
  const finishSelfie = () => {
    setOpenPopup((prev) => ({
      ...prev,
      selfie: false,
      confirmSelfie: false,
    }));
    setVideoSeen((prevState) => ({
      ...prevState,
      selfieTrack: true,
    }));
    if (backgroundVideoRef.current) backgroundVideoRef.current.play();
  };
  const finishQuiz = () => {
    setOpenPopup((prev) => ({
      ...prev,
      quiz: false,
    }));
    setVideoSeen((prevState) => ({
      ...prevState,
      quizTrack: true,
    }));
    if (backgroundVideoRef.current) backgroundVideoRef.current.play();
  };

  /**
   * MENU EVENT
   */
  useEffect(() => {
    if (mainStep !== 2) {
      // Revert tracking
      setOpenPopup((prevState) => ({
        ...prevState,
        introBooth: false,
        layerBooth: false,
      }));
      setVideoSeen((prev) => ({
        ...prev,
        introBooth1Track: false,
        introBooth2Track: false,
        introBooth3Track: false,
        introBooth4Track: false,
        introBooth5Track: false,
      }));
      setBoothTracking(
        [...Array(foyerData?.booth_video.length)].fill(true),
      );
      setBoothIndex(-1);
    }
    if (mainStep === 2) {
      setBoothTracking(
        [...Array(foyerData?.booth_video.length)].fill(false),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainStep]);
  const onClickMenu = (id: number) => {
    if (backgroundVideoRef.current) {
      const time = foyerData?.timecode_menu[id - 1];
      backgroundVideoRef.current.currentTime = time || 0;
      backgroundVideoRef.current.play();
      setVideoSeen((prevState) => ({
        ...prevState,
        selfieTrack: false,
        videoHallTrack: false,
      }));
    }
    if (id !== 1) {
      setOpenPopup((prevState) => ({
        ...prevState,
        confirmSelfie: false,
        hallVideo: false,
      }));
      setVideoSeen((prevState) => ({
        ...prevState,
        selfieTrack: true,
        videoHallTrack: true,
      }));
    }

    setOpenPopup((prev) => ({
      ...prev,
      introAgenda: false,
    }));
    setVideoSeen((prev) => ({
      ...prev,
      introAgendaTrack: false,
    }));
  };

  const nextAfterHallVideo = () => {
    if (backgroundVideoRef.current) {
      backgroundVideoRef.current.play();
      setOpenPopup((prev) => ({
        ...prev,
        hallVideo: false,
        layerHallVideo: false,
      }));
      setVideoSeen((prevState) => ({
        ...prevState,
        videoHallTrack: true,
      }));
    }
  };

  // Timeout Event start
  useEffect(() => {
    if (systemData) {
      setCountdownTimer(systemData.event_start_remaining);
    }
  }, [systemData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdownTimer(countdownTimer - 1);
    }, 1000);
    if (systemData && countdownTimer === 0) {
      clearInterval(intervalId);
      setTimeout(() => {
        dispatch(getMeAction());
      }, 500);
    }
    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTimer, systemData]);

  /**
   * SOCKET
   */
  const socketLivestream = () => {
    setLiveSocket(false);
    setLiveStatus('nolive');
    dispatch(getMeAction()).unwrap().then(() => {
      setLiveSocket(true);
      history.push(SLUG_PARAMS.LIVESTREAM);
    });
  };

  useEffect(() => {
    if (liveSocket && meData.redirect_to_page.includes('LIVESTREAM')) {
      setOpenPopup((prev) => ({
        ...prev,
        introAgenda: true,
      }));
    }
    setArrCode(meData.redirect_to_page);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meData, liveSocket, liveStatus]);

  // useDidMount(() => {
  //   const channel = echo.private(`EventTime.${meData.event_time_code}`);
  //   channel.listen('updated', socketLivestream);
  //   return () => {
  //     channel.stopListening('updated', socketLivestream);
  //   };
  // });

  useEffect(() => {
    if (routesValidRedirect.every((e) => meData.redirect_to_page.includes(e))) {
      setArrCode(meData.redirect_to_page);
      setOpenPopup((prev) => ({
        ...prev,
        introAgenda: true,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InvitationCard
        profile={profileData}
      />
      {/* <div className="t-foyer">
        <div className={`t-foyer_concept ${stepTag}`}>
          <div className="t-foyer_concept_video">
            {
              menuData
              && (
                <MenuFoyer
                  idxActive={mainStep - 1}
                  handleClick={onClickMenu}
                  menuData={menuData}
                  setActiveMenu={(idx) => setMainStep(idx + 1)}
                />
              )
            }
            <Player
              isScreen
              autoplay
              onTimeUpdate={videoBackgroundTimeUpdate}
              isMuted={isMuted}
              // src={dummyVideo}
              src={foyerData?.video_hanhtrinh || ''}
              ref={backgroundVideoRef}
              onLoaded={() => setLoading(false)}
            />
            {
              loading && (
                <div className="t-foyer_boothDetail_loading">
                  <img src={iconLoading} alt="loading" />
                </div>
              )
            }
          </div>
          {
            openPopup.layerBooth
            && (
              <BoothDetail
                handleClick={(val) => {
                  setBoothDetailLayer(val);
                  setOpenPopup((prev) => ({
                    ...prev,
                    boothPlayer: true,
                  }));
                }}
                showLayer
                order={foyerData?.booth_video[boothIndex].booth_order}
                layerClickable={foyerData?.booth_video[boothIndex].layer}
                modifiers={foyerData?.booth_video[boothIndex].name}
                src=""
                handleNext={() => {
                  playBackgroundVideo();
                  setOpenPopup((prev) => ({
                    ...prev,
                    layerBooth: false,
                  }));
                }}
              />
            )
          }
          <SoundControl
            isMuted={isMuted}
            handleSound={() => setIsMuted(!isMuted)}
          />
          {
            openPopup.layerHallVideo && (
              <div
                className="t-foyer_concept_layer"
                onClick={() => setOpenPopup((prev) => ({
                  ...prev,
                  hallVideo: true,
                }))}
              />
            )
          }
        </div>
      </div>

      <PlayerPopup
        isOpen={openPopup.hallVideo}
        handleNext={nextAfterHallVideo}
        mediaSrc={foyerData?.timecode_tracking[1].src || ''}
      />
      <PlayerPopup
        isOpen={openPopup.introBooth}
        handleNext={() => {
          setOpenPopup((prev) => ({
            ...prev,
            introBooth: false,
          }));
          playBackgroundVideo();
        }}
        mediaSrc={boothIntroBg}
        type="image"
      />
      <ConfirmSelfiePopup
        handleClose={cancelConfirmSelfie}
        openPopup={openPopup.confirmSelfie}
        handleJoin={() => setOpenPopup((prev) => ({
          ...prev,
          selfie: true,
          confirmSelfie: false,
        }))}
      />

      {
        openPopup.selfie
        && (
          <div className="t-foyer_selfie">
            <Selfie handleNext={finishSelfie} />
          </div>
        )
      }
      {
        openPopup.quiz
        && (
          <div className="t-foyer_quiz">
            <Quiz handleNext={finishQuiz} />
          </div>
        )
      }

      <PlayerPopup
        isOpen={openPopup.boothPlayer}
        type={boothDetailLayer?.type}
        modifier="download"
        handleNext={() => {
          playBackgroundVideo();
          setOpenPopup((prev) => ({
            ...prev,
            layerBooth: false,
            boothPlayer: false,
          }));
          // setOpenPopup((prev) => ({
          //   ...prev,
          //   boothPlayer: false,
          // }));
          // playBackgroundVideo();
        }}
        mediaSrc={boothDetailLayer?.src || ''}
      />

      <IntroAgendaPopup
        isLive={liveSocket}
        endVideo={finishVideo}
        arrCode={arrCode}
        liveFoyer={routesValidRedirect.every((s) => meData.redirect_to_page.includes(s))}
        handleClose={() => {
          setOpenPopup((prev) => ({
            ...prev,
            introAgenda: false,
          }));
          setLiveSocket(false);
        }}
        openPopup={openPopup.introAgenda}
        handleViewHall={() => onClickMenu(1)}
        handleViewExhibition={() => onClickMenu(2)}
      /> */}
    </>
  );
};

FoyerAio.defaultProps = {
  menuData: undefined,
  foyerData: undefined,
};

export default FoyerAio;
