import React from 'react';

import Image from 'components/atoms/Image';
import eventConfigs from 'configs';
import mapModifiers from 'utils/functions';

export type EmojiIconTypes = {
  id: number;
  name: string;
  icon: string;
  count: number;
}

interface EmojiProps {
  modifiers?: string;
  handleClick?: (val: EmojiIconTypes) => void;
}

const Emoji: React.FC<EmojiProps> = ({
  modifiers, handleClick,
}) => (
  <div className={mapModifiers('o-emoji', modifiers)}>
    {
      eventConfigs.emojiData.map((val, idx) => (
        <div
          className="o-emoji_item"
          onClick={() => handleClick && handleClick(val)}
          key={`i-${idx.toString()}`}
        >
          <Image imgSrc={val.icon} ratio="1x1" size="cover" alt={val.name} />
        </div>
      ))
    }
  </div>
);

Emoji.defaultProps = {
  handleClick: undefined,
  modifiers: undefined,
};

export default Emoji;
