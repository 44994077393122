import React, { useEffect, useState } from 'react';

import RegisterForm from '../RegisterForm';

import NumberBannerLayout from 'components/organisms/NumberBannerLayout';
import useWindowSize from 'hooks/useWindowSize';
import mapModifiers from 'utils/functions';

interface RegisterSecondProps {
}

const RegisterSecond: React.FC<RegisterSecondProps> = () => {
  const { width, height } = useWindowSize();
  const [resize, setResize] = useState(false);
  useEffect(() => {
    if (!width || !height) return;
    if (width >= 1200 && height < 1000) {
      setResize(true);
    } else setResize(false);
  }, [width, height]);

  return (
    <div className={mapModifiers('t-registerSecond', resize && 'resize')}>
      <NumberBannerLayout>
        <div className="t-registerSecond_form">
          <RegisterForm modifiers="secondary" />
        </div>
      </NumberBannerLayout>

    </div>
  );
};

RegisterSecond.defaultProps = {
};

export default RegisterSecond;
