/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from 'react';

import DeviceOrientImage2 from 'assets/images/orient-device-vertical.png';
import DeviceOrientImage from 'assets/images/orient-device.png';
import Text from 'components/atoms/Text';
import useWindowDimensions from 'hooks/useWindowDimension';

interface DeviceWrappedVerticalProps {
  requiredVertical?: boolean;
  isHidden?: boolean;
}

const DeviceWrappedVertical: React.FC<DeviceWrappedVerticalProps> = (
  { children, isHidden, requiredVertical },
) => {
  const {
    isLandscape, isMobile, width, height,
  } = useWindowDimensions();

  const landscape = useMemo(() => {
    if (isMobile && width && width < 1200 && height) {
      return width > height;
    }
    return false;
  }, [width, height, isMobile]);

  const direction = requiredVertical ? landscape : !landscape;

  // const directionVertical =

  const showDeviceOriented = useMemo(() => direction
    && isMobile && width && width < 991,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [isLandscape, isMobile, width]);

  useEffect(() => {
    if (requiredVertical && width && width < 1200) {
      // check landscape to turn off keyboard
      const input = document.getElementById('code');
      const otp1 = document.getElementById('otpInput1');
      const otp2 = document.getElementById('otpInput2');
      const otp3 = document.getElementById('otpInput3');
      const otp4 = document.getElementById('otpInput4');
      const otp5 = document.getElementById('otpInput5');
      const otp6 = document.getElementById('otpInput6');
      if (screen && screen.availHeight > screen.availWidth) {
        console.log('normal!');
      } else {
        console.log('landscape');

        input?.blur();
        otp1?.blur();
        otp2?.blur();
        otp3?.blur();
        otp4?.blur();
        otp5?.blur();
        otp6?.blur();
      }
    }
  }, [requiredVertical, width]);

  if (isHidden) return <>{children}</>;
  return (
    <>
      {
        showDeviceOriented
    && (
    <div className="t-devicewrapped">
      <img alt="mobile" src={requiredVertical ? DeviceOrientImage2 : DeviceOrientImage} />
      <Text modifiers={['white', 'center']}>
        Vui lòng xoay
        {' '}
        {requiredVertical ? 'dọc' : 'ngang'}
        {' '}
        màn hình điện thoại để trải nghiệm sự kiện một cách tốt nhất.
        <br />
        Xin Cảm ơn!
      </Text>
    </div>
    )
      }
      {children}
    </>
  );
};

DeviceWrappedVertical.defaultProps = {
  isHidden: undefined,
  requiredVertical: undefined,
};

export default DeviceWrappedVertical;
