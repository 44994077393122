import React from 'react';

import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

export type BallVariantTypes = 'empty' | 'active' | 'disabled' | 'all' | 'bingo' | '';
export interface BallProps {
  value?: string;
  variant?: BallVariantTypes;
  handleClick?: (val?: string) => void;
  size: 38 | 45 | 72 | 80 | 103 | 110;
  visible?: boolean;
}

const Ball: React.FC<BallProps> = ({
  value,
  size,
  variant = 'empty',
  visible = true,
  handleClick,
}) => (
  <div
    className={mapModifiers(
      'm-ball',
      variant,
      visible && 'visible',
      size.toString(),
      handleClick && variant !== 'disabled' && 'pointer',
    )}
    onClick={() => handleClick && variant !== 'disabled' && handleClick(value)}
  >
    <Text modifiers={['md', '600', '32x38', 'fontMyriad']}>{value}</Text>
  </div>
);

export default Ball;
