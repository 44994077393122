import React, { ChangeEvent } from 'react';

import mapModifiers from 'utils/functions';

export type NumberBallTypes = 'chosen' | 'picking' | 'silver';

interface NumberBallProps {
  type?: NumberBallTypes;
  size?: 'sm' | 'lg';
  label?: string;
  value?: string;
  picked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleClick?: () => void;
}

const NumberBall: React.FC<NumberBallProps> = ({
  value, type, size, label, picked, checked,
  disabled, handleClick, handleChange,
}) => {
  if (label) {
    return (
      <div
        className={mapModifiers('m-numberBall',
          type,
          size,
          picked && 'picked',
          label && 'label')}
        onClick={() => {
          if (!label && handleClick) handleClick();
        }}
      >
        <div className="m-numberBall_ball" />
        <div className="m-numberBall_label">
          {label}
        </div>
      </div>
    );
  }
  if (type === 'silver') {
    return (
      <div
        onClick={handleClick}
        className={mapModifiers('m-numberBall',
          type,
          size,
          disabled && 'disabled')}
      >
        <span>{value}</span>
      </div>
    );
  }
  return (
    <label
      className={mapModifiers('m-numberBall',
        type,
        size,
        disabled && 'disabled')}
      htmlFor={`number${value}`}
    >
      {
        type !== 'chosen'
        && (
          <input
            onChange={handleChange}
            value={value}
            id={`number${value}`}
            type="checkbox"
            checked={checked}
          />
        )
      }
      <div className="background" />
      <span className="value">{value}</span>
    </label>
  );
};

NumberBall.defaultProps = {
  type: undefined,
  size: undefined,
  label: undefined,
  value: undefined,
  picked: undefined,
  disabled: undefined,
  handleClick: undefined,
  handleChange: undefined,
  checked: undefined,
};

export default NumberBall;
