/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';

import PrizeNotify from 'components/templates/PrizeNotify';
import PrizeTable, { PrizeTableTypes } from 'components/templates/PrizeTable';
import useQueryParams from 'hooks/useQueryParams';
import { getPrizesService } from 'services/picking';
import { PrizesTypes } from 'services/picking/type';
import { numberWithCommas } from 'utils/functions';
import paginate from 'utils/paginations';

export type PagingTypes = {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: number[];
}

const PrizeContainer: React.FC = () => {
  const slugParams = useParams<{ slug: string }>();
  const queryParams = useQueryParams<{ page: string }>();
  const [prizeTableData, setPrizeTableData] = useState<PrizeTableTypes[]>();
  const [prizeData, setPrizeData] = useState<PrizesTypes>();
  const [openPopup, setOpenPopup] = useState(true);
  const [pagingState, setPagingState] = useState<PagingTypes>();

  useEffect(() => {
    setTimeout(() => {
      setOpenPopup(false);
    }, 5000);
    const root = document.getElementsByTagName('html')[0];
    root.classList.add('body-background');
  }, []);

  // Get me profile
  useEffect(() => {
    (async () => {
      const data = await getPrizesService();
      const objPrize = data[Number(slugParams.slug)];
      setPrizeData(objPrize);
      const result = objPrize?.details.map((val, idx) => ({
        id: idx + 1,
        name: val.member.name,
        address: val.member.address,
        number: val.number_choosed,
        area: val.member.dau_cau,
      }));
      setPrizeTableData(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const awardPrize = useMemo(
    () => (prizeData?.award ? numberWithCommas(parseInt(prizeData?.award, 10)) : 0),
    [prizeData],
  );
  const splitTableNumber = useCallback(() => {
    if (prizeData) {
      if (prizeData.number_prize >= 30) return Math.ceil(prizeData.number_prize / 4);
      if (prizeData.number_prize > 5) return Math.ceil(prizeData.number_prize / 2);
    } return 5;
  }, [prizeData]);

  useEffect(() => {
    if (prizeData) {
      const split = () => {
        if (prizeData) {
          if (prizeData.number_prize >= 30) return Math.ceil(prizeData.number_prize / 4);
          if (prizeData.number_prize > 5) return Math.ceil(prizeData.number_prize / 2);
        } return 5;
      };
      const paging = paginate(prizeData.number_prize, Number(queryParams.page || 1),
        prizeData.number_prize > 41 ? 16 : split() * 2, 3);
      setPagingState(paging);

      const result = prizeData?.details.slice(paging.startIndex, paging.endIndex + 1).map(
        (val, idx) => ({
          id: idx + 1,
          name: val.member.name,
          address: val.member.address,
          number: val.number_choosed,
          area: val.member.dau_cau,
        }),
      );
      setPrizeTableData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prizeData, queryParams]);

  const prizeName = useMemo(() => {
    switch (Number(slugParams.slug)) {
      case 0:
        return 'Đặc biệt';
      case 1:
        return 'nhất';
      case 2:
        return 'nhì';
      case 3:
        return 'ba';
      case 4:
        return 'tư';
      case 5:
        return 'năm';
      case 6:
        return 'sáu';
      default:
        return '';
    }
  }, [slugParams]);

  const prizePopupInfo = useMemo(() => {
    if (prizeTableData && prizeTableData.length > 0) {
      return {
        shopName: prizeTableData[prizeTableData.length - 1].name || '',
        number: prizeTableData[prizeTableData.length - 1].number || 0,
        address: prizeTableData[prizeTableData.length - 1].address || '',
        area: prizeTableData[prizeTableData.length - 1].area || '',
      };
    }
    return undefined;
  }, [prizeTableData]);

  return (
    <>
      <PrizeTable
        type={Number(slugParams.slug)}
        recordNumber={prizeData?.number_prize || 0}
        prizeData={prizeTableData}
        pagingData={pagingState}
        prizeInfo={{
          number: prizeData?.number_prize || 0,
          title: prizeData?.name || '',
          value: awardPrize.toString(),
        }}
        splitTable={splitTableNumber()}
      />
      {
        openPopup
        && (
          <>
            <div className="t-prize">
              <PrizeNotify
                isSpecial={Number(slugParams.slug) === 0}
                prizeNumber={Number(slugParams.slug)}
                prizeName={prizeName}
                data={prizePopupInfo}
                award={Number(prizeData?.award || 0)}
              />
            </div>
            <div className="t-prize_layer" onClick={() => setOpenPopup(false)} />
          </>
        )
      }
    </>
  );
};

export default PrizeContainer;
