import React from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Popup from 'components/organisms/Popup';

interface ResetPasswordSuccessProps {
  openPopup: boolean;
  handleClose?: () => void;
  handleClick?: ()=>void;
  phoneNumber?: string;
}

const ResetPasswordSuccess: React.FC<ResetPasswordSuccessProps> = ({
  openPopup, phoneNumber, handleClose, handleClick,
}) => (
  <div className="t-resetPasswordSuccess">
    <Popup
      hasCloseButton
      modifiers="success"
      isOpenModal={openPopup}
      title="GỬI LẠI THÔNG TIN ĐĂNG NHẬP
      THÀNH CÔNG"
      handleClose={handleClose}
    >
      <Text modifiers={['jet', '16x22']}>
        Thông tin đăng nhập của Quý khách đã được gửi về số điện thoại
        {' '}
        <Text type="span" modifiers={['pigment', '16x22']}>
          {phoneNumber}
        </Text>
        . Quý khách vui lòng kiểm tra tin nhắn để đăng nhập vào chương trình.
      </Text>
      <br />
      <div className="o-popup_button">
        <Button
          size="sm"
          variant="secondary"
          handleClick={handleClick}
        >
          ĐĂNG NHẬP
        </Button>
      </div>
    </Popup>
  </div>
);

ResetPasswordSuccess.defaultProps = {
  handleClick: undefined,
  handleClose: undefined,
  phoneNumber: undefined,
};

export default ResetPasswordSuccess;
