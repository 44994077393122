import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createSelectorHook } from 'react-redux';

import authReducer from './auth';
import checkInReducer from './checkIn';
import configsReducer from './configs';
import generalReducer from './general';
import meReducer from './me';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    auth: authReducer,
    me: meReducer,
    configs: configsReducer,
    general: generalReducer,
    checkin: checkInReducer,
  },
});
export const useAppSelector = createSelectorHook<RootState>();
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
