import axios from 'axios';

import { FoyerConfigData, LiveStreamConfigs } from './type';

const getConfigsLiveStreamService = async (): Promise<LiveStreamConfigs> => {
  const response = await axios.get('configs/livestream.json', {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
  return response.data;
};

export const getConfigsFoyerService = async (): Promise<FoyerConfigData> => {
  const res = await axios.get('configs/foyer.json', {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
  return res.data;
};

export default getConfigsLiveStreamService;
