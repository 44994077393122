import React from 'react';

import backgroundImg from 'assets/images/bg-welcome.jpg';
import logo from 'assets/images/double-logo.png';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import useWindowSize from 'hooks/useWindowSize';
import mapModifiers from 'utils/functions';

interface NumberBannerLayoutProps {
  background?: string;
  alignRight?: boolean;
  alignCenter?: boolean;
}

const NumberBannerLayout: React.FC<NumberBannerLayoutProps> = (
  {
    children, background, alignRight, alignCenter,
  },
) => {
  const { height } = useWindowSize();
  return (
    <div
      className={mapModifiers('o-numberBannerLayout',
        alignRight && 'alignRight',
        alignCenter && 'alignCenter')}
      style={{ minHeight: `${height}px` }}
    >
      <div className="o-numberBannerLayout_logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="o-numberBannerLayout_code">
        <Text modifiers={['10x14', '400', 'uppercase', 'center', 'white']}>
          COR-C-198-24
        </Text>
      </div>
      {/* BACKGROUND  */}
      <div className="o-numberBannerLayout_background">
        <Image
          imgSrc={background || backgroundImg}
          size="cover"
          alt="background"
          ratio="1366x768"
        />
      </div>
      {children}
    </div>
  );
};

NumberBannerLayout.defaultProps = {
  background: undefined,
  alignRight: undefined,
  alignCenter: undefined,
};

export default NumberBannerLayout;
