import { ShopDataTypes } from './type';

import axiosInstance from 'services/common/instance';

const getShopService = async (
  params: string,
): Promise<ShopDataTypes> => {
  const res = await axiosInstance.get(`shops/${params}`);
  return res.data.data;
};

export default getShopService;
