import { VerifyOtpDataTypes, VerifyOTPParamsTypes } from './type';

import axiosInstance from 'services/common/instance';

const postShopRegister = async (params: string) => {
  const res = await axiosInstance.post(`shops/${params}/register`);
  return res.data;
};

export const verifyOtpService = async (
  params: VerifyOTPParamsTypes,
): Promise<VerifyOtpDataTypes> => {
  const res = await axiosInstance.post(`shops/${params.code}/verify-otp`, params.otpCode);
  return res.data.data;
};

export const resendOtpService = async (params: string) => {
  const res = await axiosInstance.post(`shops/${params}/resend-otp`);
  return res.data;
};

export default postShopRegister;
