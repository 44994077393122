import React from 'react';
import Modal from 'react-modal';

import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

interface SimiPopupProps {
  isOpenPopup: boolean;
  title?: string;
  className?: string;
  bottom?: React.ReactNode;
  modifiers?: string;
  sub?: string | React.ReactNode;
  handleClose?: () => void;
  handleButtonClose?: () => void;
  hasCloseBtn?: boolean;
}

const SimiPopup: React.FC<SimiPopupProps> = ({
  children,
  title,
  isOpenPopup,
  modifiers,
  sub,
  hasCloseBtn,
  className,
  bottom,
  handleClose,
  handleButtonClose,
}) => {
  const customStyles = {
    content: {
      bottom: 'auto',
      left: '50%',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  Modal.setAppElement('#root');
  return (
    <Modal
      isOpen={isOpenPopup}
      style={customStyles}
      onRequestClose={handleClose}
      contentLabel="Example Modal"
      className={mapModifiers('o-simiPopup', modifiers, className)}
      overlayClassName={modifiers === 'chooseLocationPopup' ? 'chooseLocationPopup' : undefined}
    >
      {
        hasCloseBtn
        && (
        <div
          className="o-simiPopup_close"
          id="closePopup"
          onClick={handleButtonClose}
        />
        )
      }
      <div className="o-simiPopup_wrapper">
        {
          title && (
            <div className="o-simiPopup_title">
              <Heading type="h3" modifiers={['uppercase', '700', 'center', 'white', 'lg']}>
                {title}
              </Heading>

            </div>
          )
        }
        {sub && (
        <div className="o-simiPopup_sub">
          <Text modifiers={['400', 'white', 'center']}>
            {sub}
          </Text>
        </div>
        )}
        <div className="o-simiPopup_body">{children}</div>
        {
          bottom && bottom
        }
      </div>
    </Modal>
  );
};

SimiPopup.defaultProps = {
  title: undefined,
  className: undefined,
  bottom: undefined,
  modifiers: undefined,
  sub: undefined,
  handleClose: undefined,
  hasCloseBtn: undefined,
  handleButtonClose: undefined,
};

export default SimiPopup;
