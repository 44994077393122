/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import { menuDataFoyer } from 'assets/data/foyer';
import kvCT from 'assets/images/kv-livestream/clp-ct.jpg';
import kvDN from 'assets/images/kv-livestream/clp-dn.jpg';
import kvHCM from 'assets/images/kv-livestream/clp-hcm.jpg';
import kvHN from 'assets/images/kv-livestream/clp-hn.jpg';
import Image from 'components/atoms/Image';
import Foyer from 'components/templates/Foyer';
import FoyerAio from 'components/templates/FoyerAio';
import { getConfigsFoyerAction } from 'store/configs';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const FoyerContainer: React.FC = () => {
  const { foyer: foyerConfigs } = useAppSelector((state) => state.configs);
  const { data: meData, loading } = useAppSelector((state) => state.me);
  const dispatch = useAppDispatch();
  const renderKV = (code: string) => {
    switch (code) {
      case 'EVENT-CT-1':
        return kvCT;
      case 'EVENT-DN-1':
        return kvDN;
      case 'EVENT-HCM-1':
      case 'EVENT-HCM-2':
      case 'EVENT-HCM-3':
        return kvHCM;
      case 'EVENT-HN-1':
      case 'EVENT-HN-2':
        return kvHN;
      default:
        return '';
    }
  };
  useEffect(() => {
    dispatch(getConfigsFoyerAction());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!meData) return <>loading</>;
  if (meData.kv_livestream) {
    return <img src={renderKV(meData.event_time.code)} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />;
  }

  return (
    <FoyerAio
      menuData={menuDataFoyer}
      foyerData={foyerConfigs}
      meData={meData}
    />
    // <Foyer
    // // permissionCam={permission !== 'granted'}
    //   menuData={menuDataFoyer}
    //   foyerData={foyerConfigs}
    // />
  );
};

export default FoyerContainer;
