import React, { useEffect, useState } from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import OtpInput from 'components/molecules/OtpInput';
import Popup from 'components/organisms/Popup';

interface OtpPopupProps {
  loading?: boolean;
  openPopup: boolean;
  error?: string;
  phoneValue?: string;
  handleClose?: () => void;
  handleSubmit?: (arg: string) => void;
  handleResend?: () => void;
}

const OtpPopup: React.FC<OtpPopupProps> = ({
  loading, openPopup, error, phoneValue,
  handleClose, handleSubmit, handleResend,
}) => {
  const resendTimer = 60;
  const [otpValue, setOtpValue] = useState('');
  const [inCountdownOTP, setInCountdownOTP] = useState(false);
  const [countdownTimer, setCountdownTimer] = useState(0);

  useEffect(() => {
    if (openPopup) {
      setCountdownTimer(resendTimer);
      setInCountdownOTP(true);
    }
  }, [openPopup]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdownTimer(countdownTimer - 1);
    }, 1000);
    if (countdownTimer === 0) {
      setInCountdownOTP(false);
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [countdownTimer]);

  const handleResendOtp = () => {
    setInCountdownOTP(true);
    setCountdownTimer(resendTimer);
    if (handleResend) handleResend();
  };

  return (
    <div className="t-otpPopup">
      <Popup
        hasCloseButton
        isOpenModal={openPopup}
        title="XÁC THỰC OTP"
        handleClose={handleClose}
        modifiers="otp"
      >
        <Text modifiers={['sm', '400', 'jet']} type="span">
          Mã OTP đã được gửi tới số điện thoại
          {' '}
          <span style={{ color: '#243E90' }}>
            <strong>
              {' '}
              {phoneValue}
            </strong>
            .
          </span>
          {' '}
          Xin vui lòng nhập mã OTP để xác thực tài khoản đã đăng ký.
        </Text>
        <OtpInput handleSubmit={(val) => setOtpValue(val)} />
        {
          error !== ''
          && (
          <div className="t-otpPopup_error">
            {error}
          </div>
          )
        }
        <div className="t-otpPopup_reset">
          <div className="label">
            <Text modifiers={['sm', '400', 'pigment', 'center']} type="span">
              Chưa nhận được mã ?
            </Text>
          </div>
          {
            inCountdownOTP
              ? (
                <Text type="div" modifiers={['sm', '400', 'pigment', 'center']}>
                  Thử lại sau:
                  {' ( '}
                  {countdownTimer}
                  {' ) '}
                  s
                </Text>
              )
              : (
                <div
                  className="cursor"
                  onClick={handleResendOtp}
                >
                  <Text modifiers={['sm', '400', 'pigment', 'center']} type="span">
                    <strong> Gửi lại mã</strong>
                  </Text>
                </div>
              )
          }

        </div>
        <div className="t-otpPopup_button">
          <Button
            disabled={loading}
            loading={loading}
            handleClick={() => handleSubmit && handleSubmit(otpValue)}
            color="pigment"
            size="sm"
          >
            XÁC THỰC
          </Button>
        </div>
      </Popup>
    </div>
  );
};

OtpPopup.defaultProps = {
  handleClose: undefined,
  handleSubmit: undefined,
  handleResend: undefined,
  error: undefined,
  phoneValue: undefined,
};

export default OtpPopup;
